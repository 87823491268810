import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-simple-banner',
  templateUrl: './simple-banner.component.html',
  styleUrls: ['./simple-banner.component.sass']
})
export class SimpleBannerComponent implements OnInit {

  @Input() picture: string;
  @Input() title: string;
  @Input() titleEs: string;
  @Input() description: string;
  @Input() descriptionEs: string;
  public currentLang: any;


  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    this.currentLang = JSON.parse(localStorage.getItem('CURRENT_LANG'));
  }

}
