import { Component, OnInit, Inject, HostListener, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SuppotElement } from 'src/app/models/supportElement';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.sass']
})
export class SupportComponent implements OnInit {

  innerWidth: number;
  slideConfigPartners: any;
  elementsScreen: number;
  @Input() title: string;
  @Input() items: SuppotElement[];

  constructor(
    private translate: TranslateService) {}

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.elementsScreen = (this.innerWidth < 1024) ? ((this.innerWidth < 600) ? 1 : 2) : 4;
    this.slideConfigPartners = {
      slidesToShow: this.elementsScreen,
      slidesToScroll: 1,
      enabled: true,
      autoplay: true,
      draggable: false,
      autoplaySpeed: 1500,
      dots: false,
      initialSlide: 0,
      infinite: true,
      arrows: false,
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.elementsScreen = (this.innerWidth < 1024) ? ((this.innerWidth < 600) ? 1 : 2) : 4;
    this.slideConfigPartners = {
      slidesToShow: this.elementsScreen,
      slidesToScroll: 1,
      enabled: true,
      autoplay: true,
      draggable: false,
      autoplaySpeed: 1500,
      dots: false,
      initialSlide: 0,
      infinite: true,
      arrows: false,
    };
  }
}
