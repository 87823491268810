import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { InvestorContact } from '../models/investor-contact';

@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  private HUBSPOT_API = environment.hubspot;

  constructor(private http: HttpClient) { }

  public saveInvestorHubspot(saveBody: InvestorContact) {
    return this.http.post(this.HUBSPOT_API, saveBody, {responseType: 'json'}).pipe();
  }

}
