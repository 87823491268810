import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-simple-hero',
  templateUrl: './simple-hero.component.html',
  styleUrls: ['./simple-hero.component.sass']
})
export class SimpleHeroComponent implements OnInit {


  @Input () title: string;
  @Input () subTitle: string;

  constructor() { }

  ngOnInit() {
  }

}
