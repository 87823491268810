import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-footer-parallax',
  templateUrl: './footer-parallax.component.html',
  styleUrls: ['./footer-parallax.component.sass']
})
export class FooterParallaxComponent implements OnInit {

  @Input() image: string;
  @Input() text: string;
  @Input() buttonLabel: string;
  @Output() actionFuntion = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  OnSubmit() {
    this.actionFuntion.emit();
  }

}
