// Todo: deploying with prod connection to dev for testing new home

// export const environment = {
//   production: true,
//   hubspot:
//     'https://58erkxy4yb.execute-api.us-east-1.amazonaws.com/dev/contacts',
//   version: 'prod-1',
//   max_old_space_size: 10240,
//   agrapp_api: 'KaNWa5HKWU3EnFeCqjidW6DpGP5eCvdV40EEGVbG',
// };

export const environment = {
  production: true,
  hubspot:
    'https://58erkxy4yb.execute-api.us-east-1.amazonaws.com/dev/contacts',
  version: 'prod-1',
  max_old_space_size: 10240,
  agrapp_api: 'KaNWa5HKWU3EnFeCqjidW6DpGP5eCvdV40EEGVbG',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negat"ive impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
