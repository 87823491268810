import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";


Amplify.configure(aws_exports);

if (environment.production) {
  enableProdMode();
}

const client = new AWSAppSyncClient({
  url: aws_exports.aws_appsync_graphqlEndpoint, 
  region: aws_exports.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: aws_exports.aws_appsync_apiKey,
  },
});


document.addEventListener('DOMContentLoaded', () => {
     platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
   });
