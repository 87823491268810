export const HEADER_ITEMS: HeaderItem[] = [
  {
    label: 'Invierte',
    route: '/invierte',
  },
  {
    label: 'Proyectos',
    route: '/proyectos',
  },
  {
    label: 'Finánciate',
    route: '/agricultores',
  },
  {
    label: 'Aprende',
    route: '/aprende',
  },
  {
    label: 'Comunidad',
    route: '/comunidad',
  },
  {
    label: 'Nosotros',
    route: '/nosotros',
  },
];

export interface HeaderItem {
  label: string;
  route: string;
}
