import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SplitPipe } from '../pipes/SplitPipe';
import { MaterialModule } from './material/material.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    NgxPageScrollModule,
    MaterialModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    TranslatePipe,
    MaterialModule,
    FontAwesomeModule,
    NgxPageScrollModule
  ]
})
export class SharedModulesModule { }
