import { Component, OnInit, Input, HostListener } from '@angular/core';
import { VideoFeature } from 'src/app/models/video-feature';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-how-to-video',
  templateUrl: './how-to-video.component.html',
  styleUrls: ['./how-to-video.component.sass']
})
export class HowToVideoComponent implements OnInit {

  @Input() title: string;
  @Input() colorTitle: string;
  @Input() itemsVideo: Array<VideoFeature>;
  @Input() buttonText: string;
  @Input() buttonIco: string;
  @Input() urlImage: string;
  @Input() urlVideo: string;
  @Input() role: string;
  @Input() phoneContact: string;
  public displayContent = 'image';
  public currentLang: any;

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.currentLang = JSON.parse(localStorage.getItem('CURRENT_LANG'));
    this.displayContent = 'image';
  }

  clickChange() {
    this.displayContent = 'video';
  }

  @HostListener('document:click', ['$event'])
  documentClick(event) {
    this.currentLang = JSON.parse(localStorage.getItem('CURRENT_LANG'));
    this.translate.use(this.currentLang);
  }

  writeOnWhatsapp() {
    window.open( 'https://wa.me/' + this.phoneContact + '?text=' +
     'Quiero ser socio de un proyecto de AGRAPP como ' + this.role +
     '. Requiero información de los pasos a seguir');
  }
}
