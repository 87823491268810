import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmiterService {

  headerEvent = new EventEmitter();

  constructor() { }

  headerActions(data: string) {
    this.headerEvent.emit(data);
  }
}
