export class InvestorContact {
    nameProject?: string;
    name?: string;
    lastName?: string;
    email: string;
    mobilephone?: string;
    phone?: string;
    know?: string;
    investmentReason?: string;
    investmentIntention?: string;
    message?: string;
    typeContact?: string;
    owner?: number;
    crop?: string;
    region?: string;
    town?: string;
    hectare?: number;
    policies?: boolean;

    constructor() {
        this.nameProject = null;
        this.name = null;
        this.lastName = null;
        this.email = null;
        this.mobilephone = null;
        this.phone = null;
        this.know = null;
        this.investmentReason = null;
        this.investmentIntention = null;
        this.message = null;
        this.typeContact = null;
        this.owner = null;
        this.crop = null;
        this.region = null;
        this.town = null;
        this.hectare = null;
        this.policies = false;
    }
}
