import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogVideo {
  video: string;
}

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.sass']
})
export class VideoDialogComponent implements OnInit {

  @Input() urlVideo: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogVideo,
  ) { }

  ngOnInit(): void {
  }

}
