import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.sass']
})
export class SectionComponent implements OnInit, AfterViewInit  {

  @Input() title: string;
  @Input() content: string;
  @Input() picture: string;
  @Input() color: string;
  @Input() direction: string;

  @ViewChild('contentFlex', {static: false}) contentFlex: ElementRef;
  widthImagepx;
  heightImagepx;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.contentFlex.nativeElement.style.flexDirection  = this.direction;
    this.resizeImage();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeImage();
  }

  resizeImage() {
    if (window.innerWidth < 756) {
      this.widthImagepx = '100%';
      this.heightImagepx = 'auto';
    } else {
      this.widthImagepx = 'auto';
      this.heightImagepx = '100%';
    }
  }
}
