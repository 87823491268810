export enum COLORS {
  BRAND_BLUE = '#00aeec',
  SECONDARY_BLUE = '#0099ff',
  LIGHT_BLUE = '#b3e7f9',
  WHITE = '#ffffff',
  GREEN = '#61c300',
  YELLOW = '#ffc700',
  BLACK = '#000000',
  BLACK_2 = '#222222',
  LIGHT_GRAY = '#f2f1f0',
  LIGHT_GRAY_2 = '#f7f7f7',
  LIGHT_GRAY_3 = '#fafafa',
  DARK_GRAY = '#47443F',
  MEDIUM_GRAY = '#dcdcdc',
  DISABLED_GRAY = '#909090',
  TRANSPARENT = 'transparent',
}

export enum COLOR_NAMES {
  BRAND_BLUE = 'BRAND_BLUE',
  SECONDARY_BLUE = 'SECONDARY_BLUE',
  LIGHT_BLUE = 'LIGHT_BLUE',
  WHITE = 'WHITE',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  BLACK = 'BLACK',
  BLACK_2 = 'BLACK_2',
  LIGHT_GRAY = 'LIGHT_GRAY',
  LIGHT_GRAY_2 = 'LIGHT_GRAY_2',
  LIGHT_GRAY_3 = 'LIGHT_GRAY_3',
  DARK_GRAY = 'DARK_GRAY',
  MEDIUM_GRAY = 'MEDIUM_GRAY',
  DISABLED_GRAY = 'DISABLED_GRAY',
  TRANSPARENT = 'TRANSPARENT',
}
