import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-profile-leaf',
  templateUrl: './profile-leaf.component.html',
  styleUrls: ['./profile-leaf.component.sass']
})
export class ProfileLeafComponent implements OnInit {

  @Input() pictureSupport: string;
  @Input() alt: string;
  @Input() pathLeaf: string;

  constructor() { }

  ngOnInit() {
  }

}
