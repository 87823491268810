import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
/*  {
    path: '',
    loadChildren: () =>
      import('./page/landing/landing.module').then((m) => m.LandingModule),
  },*/
    {
    path: '',
    loadChildren: () => import('./page/landing-v2/landing-v2.module').then(m => m.LandingV2Module)
  },
  {
    path: 'invierte',
    loadChildren: () =>
      import('./page/investors/investors.module').then(
        (m) => m.InvestorsModule
      ),
  },
  {
    path: 'iníciate-en-el-mundo-de-la-agricultura-como-oportunidad-de-negocio',
    loadChildren: () =>
      import('./page/launching/launching.module').then(
        (m) => m.LaunchingModule
      ),
  },
  {
    path: 'comunidad',
    loadChildren: () =>
      import('./page/community/community.module').then(
        (m) => m.CommunityModule
      ),
  },
  {
    path: 'proyectos',
    loadChildren: () =>
      import('./page/projects-farmers/projects-farmers.module').then(
        (m) => m.ProjectsFarmerModule
      ),
  },
  {
    path: 'contacto',
    loadChildren: () =>
      import('./page/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'nosotros',
    loadChildren: () =>
      import('./page/team/team.module').then((m) => m.TeamModule),
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('./page/legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'preguntas',
    loadChildren: () =>
      import('./page/questions/questions.module').then(
        (m) => m.QuestionsModule
      ),
  },
  {
    path: 'agricultores',
    loadChildren: () =>
      import('./page/farmers/farmers.module').then((m) => m.FarmersModule),
  },
  {
    path: 'impacto',
    loadChildren: () =>
      import('./page/social-impact/social-impact.module').then(
        (m) => m.SocialImpactModule
      ),
  },
  {
    path: 'aprende',
    loadChildren: () =>
      import('./page/learn/learn.module').then((m) => m.LearnModule),
  },
  // {
  //   path: '**',
  //   loadChildren: () => import('./page/landing/landing.module').then(m => m.LandingModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
