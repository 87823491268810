import {
  AfterViewInit,
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';

import {
  // faAddressCard,
  // faAngleDoubleRight,
  // faAppleAlt,
  // faArrowCircleDown,
  faArrowRight,
  // faBars,
  faBookmark,
  // faCaretDown,
  // faCertificate,
  // faChalkboardTeacher,
  // faChartLine,
  // faCheck,
  // faCheckCircle,
  faChevronLeft,
  faChevronRight,
  // faChild,
  // faCircle,
  // faClipboardList,
  faCoins,
  // faEnvelope,
  // faEye,
  // faFemale,
  // faFile,
  // faFileAlt,
  faFileContract,
  // faFileSignature,
  // faFireExtinguisher,
  // faFistRaised,
  // faFolder,
  // faFolderOpen,
  // faGlobeAmericas,
  // faHandHoldingUsd,
  faHandshake,
  // faHandsHelping,
  faHeadSideMask,
  // faHome,
  // faLeaf,
  // faLightbulb,
  faMapMarkedAlt,
  // faMountain,
  // faPeopleArrows,
  // faPhone,
  // faPhoneAlt,
  // faPlaneDeparture,
  faPlay,
  // faPrint,
  // faProjectDiagram,
  // faRunning,
  faSeedling,
  // faShieldAlt,
  // faSignInAlt,
  // faSort,
  // faSortAmountDownAlt,
  // faSortAmountUpAlt,
  // faSpinner,
  // faTag,
  // faTools,
  // faTractor,
  // faTruckLoading,
  // faUser,
  // faUserFriends,
  // faUserPlus,
  // faUsers,
  faUserShield,
  // faVenus,
  // faWallet,
  // faWater,
  // faWindowClose,
} from '@fortawesome/free-solid-svg-icons';

import {
  //   faApple,
  //   faFacebook,
  //   faGoogle,
  //   faInstagram,
  faLinkedin,
  //   faPagelines,
  //   faTwitter,
  faWhatsapp,
  //   faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { DOCUMENT } from '@angular/common';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { MatDrawer } from '@angular/material/sidenav';
// import { MatDialog } from '@angular/material/dialog';
import { GoogleAnalyticsEventsService } from './services/google-analytics-events.service';
import { environment } from 'src/environments/environment';
import { ConsoleToggleService } from './services/ConsoleToggleService .service';
import { BreakpointsObserverService } from './services/breakpoints-observer.service';

// declare const InstallTrigger: any;

// export const WINDOW = new InjectionToken('Window');

// export function _window() {
//   return window;
// }

export const LOCALES = [
  { title: 'EN', code: 'en', thumbnail: './assets/images/en.png' },
  { title: 'SP', code: 'en', thumbnail: './assets/images/sp.png' },
];

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit, AfterViewInit {
  title = 'agrapp-app';
  // public activeLang = navigator.language === 'en-US' ? 'en' : 'es';
  // display = true;
  urlLogin = 'https://app.agrapp.co/?state=signin';
  urlAccount = 'https://app.agrapp.co/auth?state=signup';

  @ViewChild('drawer', { static: false }) drawer: any;
  // config drawable
  // isLoginView = true;
  // isSignupView = false;
  // isRecoverView = false;

  subscription: Subscription;
  public currentLang = 'es';
  // public langArray = LOCALES;
  // loading: boolean;
  // public updated = null;

  // reason = '';
  // @ViewChild('sidenav') sidenav: MatSidenav;
  // @ViewChild('sidenavMenu') sidenavMenu: MatSidenav;
  @ViewChild('drawer') sideMenu: MatDrawer;

  // private investorInsert: InvestorContact;
  // private currentIdProject: string;
  // formInvestor = new FormGroup({});
  // public knowArray = knowArray;
  // public reasonArray = reasonArray;
  // public amountArray = amountArray;
  private destroySubscription: Subject<boolean> = new Subject<boolean>();

  constructor(
    private translate: TranslateService,
    // private eventEmiterService: EventEmiterService,
    public router: Router,
    library: FaIconLibrary,
    // private sidenavService: SidenavService,
    @Inject(DOCUMENT) private document: Document,
    // private hubspotService: HubspotService,
    // public dialog: MatDialog,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private consoleToggleService: ConsoleToggleService,
    public breakpointObserverService: BreakpointsObserverService
  ) {
    // this.dialog.open(BuilderMessagesComponent, {
    //   height: '600px',
    //   width: '800px',
    //   disableClose: true
    // });
    this.breakpointObserverService.getScreenWidth(this.destroySubscription);
    //TODO Enable when deploy in production
    // this.consoleToggleService.disableConsoleInProduction();

    // this.investorInsert = new InvestorContact();
    library.addIcons(
      //   faHome,
      //   faEnvelope,
      //   faPhone,
      //   faPhoneAlt,
      //   faPrint,
      //   faFacebook,
      //   faTwitter,
      faLinkedin,
      //   faYoutube,
      //   faInstagram,
      //   faHandHoldingUsd,
      //   faCircle,
      //   faArrowCircleDown,
      //   faRunning,
      //   faTractor,
      //   faMountain,
      //   faAppleAlt,
      //   faTag,
      //   faChartLine,
      //   faLeaf,
      //   faUser,
      //   faLightbulb,
      //   faTools,
      faSeedling,
      faMapMarkedAlt,
      //   faCheck,
      //   faSortAmountUpAlt,
      //   faSortAmountDownAlt,
      //   faGlobeAmericas,
      //   faSort,
      //   faCaretDown,
      //   faApple,
      //   faVenus,
      //   faGoogle,
      //   faAddressCard,
      //   faClipboardList,
      //   faCheckCircle,
      faWhatsapp,
      faPlay,
      //   faPagelines,
      //   faTruckLoading,
      //   faCertificate,
      //   faBars,
      //   faFileContract,
      //   faWindowClose,
      //   faShieldAlt,
      faBookmark,
      //   faFileSignature,
      faHandshake,
      //   faFistRaised,
      //   faFireExtinguisher,
      //   faEye,
      //   faWater,
      //   faPlaneDeparture,
      //   faFemale,
      //   faChild,
      //   faSpinner,
      //   faUserFriends,
      //   faHandsHelping,
      //   faFolder,
      //   faAngleDoubleRight,
      //   faFileAlt,
      //   faFile,
      //   faProjectDiagram,
      //   faPeopleArrows,
      //   faUsers,
      //   faFolderOpen,
      faArrowRight,
      faChevronRight,
      faChevronLeft,
      //   faChalkboardTeacher,
      //   faWallet,
      faCoins,
      faHeadSideMask,
      faUserShield,
      faFileContract
      //   faUserPlus,
      //   faSignInAlt
    );
    this.translate.setDefaultLang('es');
    translate.use(this.currentLang);
    this.document.documentElement.lang = this.currentLang;
  }

  ngOnInit() {
    const tempLang = localStorage.getItem('CURRENT_LANG');
    this.currentLang = JSON.parse(tempLang);
    if (!this.currentLang) {
      // Use by default spanish language
      this.currentLang = 'es';
      this.document.documentElement.lang = this.currentLang;
      localStorage.setItem('CURRENT_LANG', JSON.stringify(this.currentLang));
    }

    // this.routerReciber();

    // this.sidenavService.observer.subscribe((data) => {
    //   this.currentIdProject = (data as EmitData).extraData;
    //   this.open();
    // });

    // this.sidenavService.observerMenu.subscribe((status) => {
    //   if (status === 'open') {
    //     this.openMenu();
    //   } else {
    //     this.closeMenu('external close');
    //   }
    // });

    // this.formInvestor = new FormGroup({
    //   name: new FormControl('', Validators.required),
    //   lastname: new FormControl('', Validators.required),
    //   email: new FormControl('', Validators.pattern(AppSettings.emailRegexp)),
    //   phone: new FormControl('', Validators.minLength(7)),
    //   reason: new FormControl('', Validators.required),
    //   agreement: new FormControl('', Validators.required),
    // });

    if (environment.production) {
      this.urlAccount = 'https://app.agrapp.co/auth?state=signup';
      this.urlLogin = 'https://app.agrapp.co/?state=signin';
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const element = document.getElementsByClassName('main')[0];
        element.scrollTo(0, 0);
      }
    });
  }

  // ngAfterViewInit() {
  //   this.router.events.subscribe((event: RouterEvent): void => {
  //     if (event instanceof NavigationStart) {
  //       this.display = true;
  //     } else if (event instanceof NavigationEnd) {
  //       this.display = false;
  //     }
  //   });
  // }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  // onSubmit() {
  //   this.investorInsert.name = this.formInvestor.value.name;
  //   this.investorInsert.lastName = this.formInvestor.value.lastname;
  //   this.investorInsert.phone = this.formInvestor.value.phone;
  //   this.investorInsert.email = this.formInvestor.value.email;
  //   this.investorInsert.investmentReason = this.formInvestor.value.reason;
  //   this.investorInsert.policies = this.formInvestor.value.policies;
  //   this.investorInsert.nameProject = this.currentIdProject;
  //   this.investorInsert.know = 'Web';
  //   this.investorInsert.owner = AppSettings.ownerHubspot.investOwner1;
  //   this.investorInsert.typeContact = AppSettings.typeContact.Investor;
  //
  //   this.hubspotService
  //     .saveInvestorHubspot(this.investorInsert)
  //     .subscribe((resp: ResponseHubspot) => {
  //       if (resp.code === 200) {
  //         this.openDialog(resp.data.name);
  //         this.sendAnalytics(
  //           'call_to_action_investor',
  //           'investor_form_submitted',
  //           '',
  //           'send'
  //         );
  //       } else {
  //         this.updated = null;
  //       }
  //       this.formInvestor.reset();
  //     });
  // }

  // openDialog(name: string) {
  //   // tslint:disable-next-line: max-line-length
  //   const textAlertEs =
  //     name +
  //     ', hemos enviado la información del proceso de inversión a tu correo, en breve uno de nuestros asesores se contactará por vía telefónica para continuar el proceso.  ';
  //   // tslint:disable-next-line: max-line-length
  //   const textAlertEn =
  //     name +
  //     ', We have sent the information of the investment process to your email, shortly one of our advisers will be contacted by phone to continue the process.';
  //   const colorText = '#000';
  //
  //   const dialogRef = this.dialog.open(AlertDialogComponent, {
  //     height: '380px',
  //     width: '320px',
  //     panelClass: 'custom-modalbox',
  //     data: {
  //       textAlertEs,
  //       textAlertEn,
  //       colorText,
  //     },
  //   });
  //
  //   dialogRef.afterClosed().subscribe((result) => {
  //     this.updated = true;
  //     this.close('complete Dialog');
  //   });
  // }

  // sendAnalytics(category, label, value?, event?) {
  //   this.googleAnalyticsEventsService.emitEvent(category, event, label, value);
  // }

  // headerActions($event) {
  //   if ($event === 'sign-up') {
  //     this.isLoginView = false;
  //     this.isSignupView = true;
  //     this.isRecoverView = false;
  //   }
  //
  //   if ($event === 'recover') {
  //     this.isLoginView = false;
  //     this.isSignupView = false;
  //     this.isRecoverView = true;
  //   }
  //
  //   if ($event === 'log-in') {
  //     this.isLoginView = true;
  //     this.isSignupView = false;
  //     this.isRecoverView = false;
  //   }
  // }

  // reciberHeaderMessage($event) {
  //   const msg: string = $event;
  //
  //   if (msg === 'en' || msg === 'es') {
  //     this.changeLanguaje(msg);
  //   } else {
  //     this.drawer.toggle();
  //     this.headerActions(msg);
  //   }
  // }
  //
  // changeLanguaje(language: string) {
  //   this.activeLang = language;
  //   this.document.documentElement.lang = this.activeLang;
  //   this.translate.setDefaultLang(this.activeLang);
  // }

  // routerReciber() {
  //   this.eventEmiterService.headerEvent.subscribe((data) => {
  //     this.headerActions(data);
  //     this.drawer.toggle();
  //   });
  // }

  ngOnDestroy() {
    this.destroySubscription.next(true);
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
  }

  // close(reason: string) {
  //   this.reason = reason;
  //   this.sidenav.close();
  // }
  //
  // closeMenu(url: string) {
  //   this.router.navigate([url]);
  //   this.sidenavMenu.close();
  // }
  //
  // openAccount() {
  //   console.log('open account', this.urlAccount);
  //   window.open(this.urlAccount, '_self');
  //   this.sidenavMenu.close();
  // }
  //
  // openLogin() {
  //   window.open(this.urlLogin, '_self');
  //   this.sidenavMenu.close();
  // }
  //
  // open() {
  //   this.sidenav.open();
  // }
  //
  // openMenu() {
  //   this.sidenavMenu.open();
  // }

  // detectWebpSuport() {
  //   // Opera 8.0+
  //   const isOpera: boolean =
  //     (!!window['opr'] && !!window['opr'].addons) ||
  //     !!window['opera'] ||
  //     navigator.userAgent.indexOf(' OPR/') >= 0;
  //
  //   // Firefox 1.0+
  //   const isFirefox: boolean = typeof InstallTrigger !== 'undefined';
  //
  //   // Internet Explorer 6-11
  //   const isIE: boolean =
  //     /*@cc_on!@*/ false || !!window.document['documentMode'];
  //
  //   // Chrome 1 - 71
  //   const isChrome: boolean =
  //     !!window['chrome'] &&
  //     (!!window['chrome'].webstore || !!window['chrome'].runtime);
  //
  //   if (isOpera === true || isFirefox === true || isChrome) {
  //     return 'true';
  //   } else {
  //     return 'false';
  //   }
  // }

  async openSideMenu() {
    await this.sideMenu.open();
  }

  async closeSideMenu() {
    await this.sideMenu.close();
  }
}
