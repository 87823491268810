import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { USERS } from '../mocks/mock-users';
import { environment } from '../../environments/environment';
import { User } from '../models/user';





@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private endPoint = '/users';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Accept': 'application/json'
    })
  };

  constructor(
    private http: HttpClient
    ) { }

  getInvestors(): Observable<User[]> {
    return  of(USERS.filter(invest => invest.role === 'investor'));
  }

  getFarmersTestimony(): Observable<User[]> {
    return  of(
      USERS.filter(farmer => (farmer.role === 'farmer' && farmer.phrase !== ''))
          .sort((a, b) => {
            return a < b ? 1 : -1;
         })
      );
  }

  getLocalUsers(): Observable<User[]> {
    return of(USERS);
  }

  getUser(id: string): Observable<User> {
    return of(USERS.find(user => user.id === id));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

}
