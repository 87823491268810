import { Component, OnInit, Input, HostListener } from '@angular/core';
import { SuppotElement } from 'src/app/models/supportElement';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-allies',
  templateUrl: './allies.component.html',
  styleUrls: ['./allies.component.sass']
})
export class AlliesComponent implements OnInit {

  innerWidth: number;
  slideConfigPartners: any;
  elementsScreen: number;
  @Input() items: SuppotElement[];

  constructor(
    private translate: TranslateService) {}

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.elementsScreen = (this.innerWidth < 1024) ? ((this.innerWidth < 600) ? 1 : 3) : 5;
    this.slideConfigPartners = {
      slidesToShow: this.elementsScreen,
      slidesToScroll: 3,
      enabled: true,
      autoplay: true,
      draggable: false,
      autoplaySpeed: 2000,
      dots: true,
      initialSlide: 0,
      infinite: true,
      arrows: true,
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.elementsScreen = (this.innerWidth < 1024) ? ((this.innerWidth < 600) ? 1 : 2) : 4;
    this.slideConfigPartners = {
      slidesToShow: this.elementsScreen,
      slidesToScroll: 3,
      enabled: true,
      autoplay: true,
      draggable: false,
      autoplaySpeed: 2000,
      dots: true,
      initialSlide: 0,
      infinite: true,
      arrows: true,
    };
  }
}
