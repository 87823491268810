import { Component, OnInit, HostListener, Inject, Input } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { User } from 'src/app/models/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partner-projects',
  templateUrl: './partner-projects.component.html',
  styleUrls: ['./partner-projects.component.sass']
})
export class PartnerProjectsComponent implements OnInit {

  investors: User[];
  innerWidth: number;
  elementsScreen = 3;
  slideConfig: any;
  centermode = false;
  @Input() colorElement: string;
  pathLeaf: string;

  constructor(
    private usersService: UsersService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.elementsScreen = (this.innerWidth < 1024) ? 1 : 3;
    this.centermode = (this.innerWidth < 1024) ? true : false;
    this.slideConfig = {
      'slidesToShow': this.elementsScreen,
      'slidesToScroll': this.elementsScreen,
      'enabled': true,
      'autoplay': true,
      'draggable': true,
      'autoplaySpeed': 2500,
      'dots': true,
      'initialSlide': 0,
      'infinite': true,
      'centerMode': this.centermode,
      'variableWidth': this.centermode,
      'arrows': false,
    };
    this.usersService.getInvestors().subscribe(
      data => this.investors = data
    );
    if (this.colorElement === '#FF9933') {
      this.pathLeaf = 'https://agrappfinancial.s3.amazonaws.com/image/svg/leafYellow.svg';
    } else {
      this.pathLeaf = 'https://agrappfinancial.s3.amazonaws.com/image/svg/leafBlue.svg';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.elementsScreen = (this.innerWidth < 1024) ? 1 : 3;
    this.centermode = (this.innerWidth < 1024) ? true : false;
    this.slideConfig = {
      'slidesToShow': this.elementsScreen,
      'slidesToScroll': this.elementsScreen,
      'enabled': true,
      'autoplay': true,
      'draggable': true,
      'autoplaySpeed': 2500,
      'dots': true,
      'initialSlide': 0,
      'infinite': true,
      'centerMode': this.centermode,
      'variableWidth': this.centermode,
      'arrows': false,
    };
  }

}
