import { Pipe, PipeTransform } from '@angular/core';
import { COLORS } from '../constants/colors';

@Pipe({
  name: 'contractionColorInHex',
})
export class ContractionColorInHexPipe implements PipeTransform {
  transform(color: string): string | boolean {
    return COLORS[color] || false;
  }
}
