import { Component, OnInit, Input, ElementRef, ViewChild, HostListener, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-focus-image',
  templateUrl: './focus-image.component.html',
  styleUrls: ['./focus-image.component.sass']
})
export class FocusImageComponent implements OnInit, AfterViewInit {

  @Input() picture;
  @Input() startTransform: number;
  @Input() endTransform: number;
  @Input() widthImage: string;
  @Input() heightImage: string;

  @ViewChild('content', {static: false}) content: ElementRef;
  @ViewChild('image', {static: false}) image: ElementRef;

  constructor() { }

  ngOnInit() {}

  ngAfterViewInit() {
    this.image.nativeElement.style.transform = 'scale(' + this.startTransform + ')';
    this.image.nativeElement.style.backgroundSize = this.widthImage + ' ' + this.heightImage;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.image.nativeElement.style.backgroundSize = this.widthImage + ' ' + this.heightImage;
  }

  mouseEnter() {
    this.image.nativeElement.style.transform = 'scale(' + this.endTransform + ')';
  }

  mouseLeave() {
    this.image.nativeElement.style.transform = 'scale(' + this.startTransform + ')';
  }

}
