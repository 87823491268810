import { Injectable } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { map, startWith, takeUntil, throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointsObserverService {
  public isDesktopView!: boolean;
  public screenWidth = window.innerWidth;

  public getScreenWidth(destroySubscription: Subject<boolean>) {
    this.isMobileObserver$()
      .pipe(startWith(window.innerWidth), takeUntil(destroySubscription))
      .subscribe((screenWidth) => {
        this.screenWidth = screenWidth;
        this.isDesktopView = screenWidth > 1200;
      });
  }

  private isMobileObserver$() {
    return fromEvent(window, 'resize').pipe(
      throttleTime(300),
      map((event) => +(event.currentTarget as Window).innerWidth)
    );
  }
}
