import { User } from '../models/user';


export const USERS: any[] = [
    {
        id: '1',
        name: 'María de Jesús',
        lastname: 'Murcia',
        email: '',
        password: 'agrapp2328',
        isPolicies: true,
        phone: '0',
        documentNumber: '',
        pictureSupport: 'https://agrappfinancial.s3.amazonaws.com/agricultor/25.maria_murcia4x3.jpg',
        role: 'farmer',
        phrase: 'Gracias al apoyo de Agrapp, pudimos sacar adelante nuestro primer cultivo de Gulupa.',
        largePicture: ''
    },
    {
        id: '2',
        name: 'Francisco',
        lastname: 'Franco',
        email: '',
        password: 'agrapp2328',
        isPolicies: true,
        phone: '0',
        documentNumber: '',
        pictureSupport: 'https://agrappfinancial.s3.amazonaws.com/image/web/francisco.jpg',
        videoUrl: 'https://agrappfinancial.s3.amazonaws.com/agricultor/Video/franciscoFranco.mp4',
        role: 'farmer',
        // tslint:disable-next-line: max-line-length
        phrase: 'Gracias al apoyo brindado por Agrapp en el área técnica, sacamos adelante nuestro proyecto de melón en ciénaga de oro, Cesar. A su vez con el apoyo de los inversionistas nos fue posible comprar insumos para el manejo fitosanitario del cultivo.',
        largePicture: ''
    },
    {
        id: '5',
        name: 'Luis Ernesto',
        lastname: 'Montoya',
        email: '',
        password: 'agrapp2328',
        isPolicies: true,
        phone: '0',
        documentNumber: '',
        pictureSupport: 'https://agrappfinancial.s3.amazonaws.com/image/web/luisernesto.PNG',
        videoUrl: 'https://agrappfinancial.s3.amazonaws.com/image/video/Agrapp+es+la+voz+del+agricultor.mp4',
        role: 'farmer',
        // tslint:disable-next-line: max-line-length
        phrase: 'Trabajando con la mano de Agrapp y gracias a la asistencia técnica, voy a certificar mi predio, como predio exportador, cumpliendo las buenas prácticas agrícolas por la reglamentación ICA',
        largePicture: ''
    },
    {
        id: '7',
        name: 'Julio Andres',
        lastname: 'Bolaños Pulido',
        email: '',
        password: 'agrapp2328',
        isPolicies: true,
        phone: '0',
        documentNumber: '',
        pictureSupport: 'https://agrappfinancial.s3.amazonaws.com/image/testimonios/julio.jpg',
        role: 'investor',
        // tslint:disable-next-line: max-line-length
        phrase: 'Gracias al equipo de Agrapp quien me guió en el proceso para realizar la mejor inversión, hoy en día soy socio del proyecto de melón de Francisco. He podido realizar seguimiento de su proceso y estoy orgulloso de ser parte del proyecto. ',
        largePicture: ''
    },
    {
        id: '8',
        name: 'Cristina',
        lastname: 'Mejia Cancelado',
        email: '',
        password: 'agrapp2328',
        isPolicies: true,
        phone: '0',
        documentNumber: '',
        pictureSupport: 'https://agrappfinancial.s3.amazonaws.com/image/testimonios/cristina2.jpg',
        role: 'investor',
        // tslint:disable-next-line: max-line-length
        phrase: 'La agricultura debe ser el motor productivo de este país. Es necesario contar con infraestructura y capacidad instalada en el territorio, creo que Agrapp lo está generando. No sólo invertí mis ahorros en el proyecto doña María, sino que invertí a largo plazo en el campo colombiano.',
        largePicture: ''
    },
    {
        id: '9',
        name: 'John',
        lastname: 'Higuera',
        email: '',
        password: 'agrapp2328',
        isPolicies: true,
        phone: '0',
        documentNumber: '',
        pictureSupport: 'https://agrappfinancial.s3.amazonaws.com/image/testimonios/john.jpg',
        role: 'investor',
        // tslint:disable-next-line: max-line-length
        phrase: 'He realizado mi inversión y estoy recibiendo mis pagos gracias a la utilidad que se genera del proyecto.  Son proyectos de gran proyección que ayudan al impulso de la agricultura en mi país.',
        largePicture: ''
    },
    {
        id: '10',
        name: 'Cesar',
        lastname: 'Peña',
        email: '',
        password: 'agrapp2328',
        isPolicies: true,
        phone: '0',
        documentNumber: '',
        pictureSupport: 'https://agrappfinancial.s3.amazonaws.com/image/testimonios/cesar.jpg',
        role: 'investor',
        // tslint:disable-next-line: max-line-length
        phrase: 'Ya he recibido mi primer retorno de inversión. Durante el proceso me mantuvieron constantemente informado. Estoy contento con la rentabilidad y espero poder ser parte de otro proyecto de nuevo.',
        largePicture: ''
    },
    {
        id: '13',
        name: 'Luz Mireya',
        lastname: 'Aguilera Urrea',
        email: '',
        password: 'agrapp2328',
        isPolicies: true,
        phone: '0',
        documentNumber: '',
        pictureSupport: 'https://agrappfinancial.s3.amazonaws.com/agricultor/mireya.jpg',
        videoUrl: 'https://agrappfinancial.s3.amazonaws.com/proyectos/1571961043_MyrianAguilera/video.mp4',
        role: 'farmer',
        // tslint:disable-next-line: max-line-length
        phrase: 'Soy Luz Mireya, junto con mi hija hemos desarrollado varios proyectos agrícolas. Quiero seguir generando ingreso para las madres cabeza de familia que trabajan en mi finca.',
        largePicture: ''
    },
    {
        id: '17',
        name: 'Jenny Carolina',
        lastname: 'Gomez Pulido',
        email: '',
        password: 'agrapp2328',
        isPolicies: true,
        phone: '0',
        documentNumber: '',
        pictureSupport: 'https://agrappfinancial.s3.amazonaws.com/agricultor/Video/min_jenny_gomez.jpg',
        videoUrl: 'https://agrappfinancial.s3.amazonaws.com/agricultor/Video/JennyGomez.mp4',
        role: 'farmer',
        phrase: 'Tengo un proyecto productivo de Gulupa; Gracias a Paz y flora y Agrapp, puedo garantizar la venta de mi cosecha.',
        largePicture: ''
    },
    {
        id: '20',
        name: 'Wilson Gabriel',
        lastname: 'Vargas Corzo',
        email: '',
        password: 'agrapp2328',
        isPolicies: true,
        phone: '0',
        documentNumber: '',
        pictureSupport: 'https://agrappfinancial.s3.amazonaws.com/agricultor/willson_profile.jpg',
        role: 'farmer',
        // tslint:disable-next-line: max-line-length
        phrase: 'Ya tenemos 670 varas y sembradas 390 plántulas de granadilla. Las plántulas salieron magnificas y tienen un buen desarrollo. Próximamente estaremos finalizando el emparrado.',
        largePicture: ''
    }
];
