import {
  ListProjectByPublishQuery,
  ModelSortDirection,
  ProjectListService,
  PublishClassifier,
} from './../../services/project-list.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsEventsService } from 'src/app/services/google-analytics-events.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.sass'],
})
export class ProjectsComponent implements OnInit {
  projects: any[] = [];
  @Input() colorSection: string;

  public currentLang: any;
  innerWidth: number;
  elementsScreen = 3;
  elementsScroll = 1;
  centerPadding = '40px';
  slideConfigProject: any;
  centermode = false;
  nameFarmer = '';
  lastnameFarmer = '';
  MININVEST = 500000;

  constructor(
    private router: Router,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private apiService: ProjectListService
  ) {}

  ngOnInit() {
    this.reSizeCarrousel();
    this.slideConfigProject = {
      slidesToShow: this.elementsScreen,
      slidesToScroll: this.elementsScroll,
      enabled: true,
      autoplay: true,
      draggable: true,
      autoplaySpeed: 2500,
      dots: true,
      initialSlide: 0,
      infinite: true,
      centerMode: this.centermode,
      centerPadding: this.centerPadding,
      variableWidth: this.centermode,
      arrows: false,
    };

    this.currentLang = JSON.parse(localStorage.getItem('CURRENT_LANG'));
    this.apiService
      .ListProjectsByPublish(
        PublishClassifier.PUBLISHED,
        null,
        ModelSortDirection.DESC
      )
      .then((event) => {
        // this.projects = this.shuffle(event.items); // Ramdom array
        (event as ListProjectByPublishQuery).items.map((item) => {
          let nameFarmer = '';
          let lastNameFarmer = '';

          this.apiService.Decrypt(item.farmerData.user.name).then((event) => {
            nameFarmer = event.split('body=')[1].replace('}', '');

            this.apiService
              .Decrypt(item.farmerData.user.lastName)
              .then((event) => {
                lastNameFarmer = event.split('body=')[1].replace('}', '');

                let element = {
                  projetcId: item.id,
                  cropName: item.crop.name,
                  cropNameEs: item.crop.nameEs,
                  cropPicture: item.crop.picture,
                  farmerName: nameFarmer,
                  farmerLastname: lastNameFarmer,
                  farmerProfile: item.farmerData.user.profilePhoto,
                  projectState: item.currentStateProject.projectState,
                  currentPublishClassifier: item.currentPublishClassifier,
                  headTownName: item.headTown.headTownName,
                  regionName: item.headTown.town.region.regionName,
                  rate: item.rate,
                  investmentGoal: item.investmentGoal,
                  financed: item.financed,
                  numberPayments: item.numberPayments,
                  projectTransaction: item.projectTransaction,
                  projectHistoricalState: item.projectHistoricalState,
                  counterpartFarmer: item.counterpartFarmer,
                  totalCost: item.totalCost,
                };
                this.projects.push(element);
              });
          });
        });
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.reSizeCarrousel();
    this.slideConfigProject = {
      slidesToShow: this.elementsScreen,
      slidesToScroll: this.elementsScroll,
      enabled: true,
      autoplay: true,
      draggable: true,
      autoplaySpeed: 2500,
      dots: true,
      initialSlide: 0,
      infinite: true,
      centerMode: this.centermode,
      centerPadding: this.centerPadding,
      variableWidth: this.centermode,
      arrows: false,
    };
  }

  openProjects() {
    this.router.navigateByUrl('/proyectos');
    this.sendAnalytics(
      'atraction_investors',
      'click_on_more_projects',
      '',
      'click'
    );
  }

  sendAnalytics(category, label, value?, event?) {
    this.googleAnalyticsEventsService.emitEvent(category, event, label, value);
  }

  reSizeCarrousel() {
    this.innerWidth = window.innerWidth;
    this.elementsScreen = this.innerWidth < 1024 ? 1 : 3;
    this.elementsScroll = this.innerWidth < 1024 ? 1 : 3;
    this.centerPadding = '15px';
    this.centermode = this.innerWidth < 1024 ? true : false;
  }

  shuffle(array: any) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  getPartners(project) {
    return project.projectTransaction.items.filter(
      (transaction) => transaction.currentTransactionState == 'COMPLETED'
    ).length;
  }

  getPublishDate(project) {
    let createdAt = project.projectHistoricalState.items.filter(
      (state) =>
        state.projectGropStateID == 'f4243400-6ca7-49e0-a871-4fedca2e38ed'
    );
    if (createdAt.length == 0) {
      return new Date();
    }
    return new Date(createdAt[0].createdAt);
  }
}
