export const knowArray = [
  { idName: 'Agroexpo', nameEs: 'Agroexpo', nameEn: 'Agroexpo' },
  { idName: 'Chat-web', nameEs: 'Chat-web', nameEn: 'Chat-web' },
  { idName: 'Go Fest', nameEs: 'Go Fest', nameEn: 'Go Fest' },
  {
    idName: 'Primavera Fest',
    nameEs: 'Primavera Fest',
    nameEn: 'Primavera Fest',
  },
  {
    idName: 'Redes sociales',
    nameEs: 'Redes sociales',
    nameEn: 'Social networks',
  },
  { idName: 'Web', nameEs: 'Web', nameEn: 'Web' },
  {
    idName: 'Demoday Rockstar',
    nameEs: 'Demoday Rockstar',
    nameEn: 'Demoday Rockstar',
  },
  { idName: 'Inve.co', nameEs: 'Inve.co', nameEn: 'Inve.co' },
];

export const reasonArray = [
  {
    idName: 'Amor',
    nameEs: 'Amor por la agricultura',
    nameEn: 'Love for agriculture',
  },
  {
    idName: 'Diversificar',
    nameEs: 'Diversificar inversión',
    nameEn: 'Diversify investment',
  },
  { idName: 'Impacto', nameEs: 'Impacto social', nameEn: 'Social Impact' },
  { idName: 'Rentabilidad', nameEs: 'Rentabilidad', nameEn: 'Profitability' },
];

export const amountArray = [
  {
    idName: '1a3',
    nameEs: 'Entre 1 millones a 3 millones',
    nameEn: 'Between 1 million to 3 million',
  },
  {
    idName: '3a6',
    nameEs: 'Entre 3 millones a 6 millones',
    nameEn: 'Between 3 million to 6 million',
  },
  {
    idName: '6a9',
    nameEs: 'Entre 6 millones a 9 millones',
    nameEn: 'Between 6 million to 9 million',
  },
  {
    idName: 'mas9',
    nameEs: 'Mas de 9 millones',
    nameEn: 'More than 9 million',
  },
];
