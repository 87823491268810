import { SidenavService } from 'src/app/services/sidenav.service';
import {
  Component,
  OnInit,
  HostListener,
  Input,
  AfterViewInit,
} from '@angular/core';
import { ImageService } from '../../services/image.service';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsEventsService } from 'src/app/services/google-analytics-events.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements AfterViewInit {
  // Vars
  isTop = false;
  logo: string;
  jsoncomplete: any;
  isOpenMobileMenu = false;
  esClass = 'language-press';
  enClass = 'language-unpress';
  urlLogin = 'https://app.agrapp.co/?state=signin';
  urlAccount = 'https://app.agrapp.co/auth?state=signup';

  // Inputs
  @Input() drawer: any = null;
  @Input() language: string;

  public islogged = false;
  colorFont: string;

  constructor(
    private imageService: ImageService,
    private translate: TranslateService,
    private sidenavService: SidenavService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private router: Router
  ) {}

  ngAfterViewInit() {
    this.imageService.getImages().subscribe((data) => {
      this.logo = data['logo'];
    });
  }

  headerAction(msg: string) {
    if (msg === 'es') {
      this.esClass = 'language-press';
      this.enClass = 'language-unpress';
    } else if (msg === 'en') {
      this.esClass = 'language-unpress';
      this.enClass = 'language-press';
    }
    localStorage.setItem('CURRENT_LANG', JSON.stringify(msg));
    if (environment.production) {
      this.urlAccount = 'https://app.agrapp.co/auth?state=signup';
      this.urlLogin = 'https://app.agrapp.co/?state=signin';
    }
    this.translate.use(msg);
  }

  toogleMobileMenu() {
    this.isOpenMobileMenu = !this.isOpenMobileMenu;
  }

  openMainMenu() {
    this.sidenavService.emitMenuLogout('open');
  }

  openHome() {
    this.router.navigateByUrl('/');
  }

  openAccount() {
    window.open(this.urlAccount, '_self');
  }

  openLogin() {
    window.open(this.urlLogin, '_self');
  }
}
