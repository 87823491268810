import { Component } from '@angular/core';
import { BreakpointsObserverService } from '../../services/breakpoints-observer.service';
import {
  aboutUsFooterTabs,
  contactUsFooterTabs,
  legalFooterTabs,
  socialMediaFooterIcons,
} from '../../constants/footer-tabs';
import { FooterTab } from '../../interfaces/footer-tab';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-v2',
  templateUrl: './footer-v2.component.html',
  styleUrls: ['./footer-v2.component.scss'],
})
export class FooterV2Component {
  public readonly legalFooterTabs: FooterTab[] = legalFooterTabs;
  public readonly aboutUsFooterTabs: FooterTab[] = aboutUsFooterTabs;
  public readonly contactUsFooterTabs: FooterTab[] = contactUsFooterTabs;
  public readonly socialMediaFooterIcons: FooterTab[] = socialMediaFooterIcons;

  constructor(
    private router: Router,
    public breakpointObserverService: BreakpointsObserverService
  ) {}

  public async openFooterTab(legalFooterTab: FooterTab) {
    if (legalFooterTab.isExternal) {
      window.open(legalFooterTab.route, '_blank');
    } else {
      await this.router.navigate([legalFooterTab.route]);
    }
  }
}
