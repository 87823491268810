import { Component, OnInit, Inject, HostListener, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  textAlertEs: string;
  textAlertEn: string;
  colorText: string;
  buttonTextEs: string;
  buttonTextEn: string;
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.sass']
})
export class AlertDialogComponent implements OnInit {

  public currentLang: any;
  @ViewChild('capTitle', {static: false}) capTitle: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private translate: TranslateService,
  ) {  }

  ngOnInit() {
    this.currentLang = JSON.parse(localStorage.getItem('CURRENT_LANG'));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event) {
    this.currentLang = JSON.parse(localStorage.getItem('CURRENT_LANG'));
    this.translate.use(this.currentLang);
  }
}
