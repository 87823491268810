import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Colombia } from '../models/colombia';

@Injectable({
  providedIn: 'root'
})
export class ColombiaService {

  private apiCountries = 'https://restcountries.eu/rest/v2/all';
  private apiDepColombia = 'https://agrappfinancial.s3.amazonaws.com/image/data/departamentos-colombia.json';

  constructor(
    private http: HttpClient
  ) { }

  public getCountries() {
    return this.http.get(this.apiCountries)
      .pipe(map(res => res || []
      ));
  }

  public getCoordinates() {
    return this.http.get(this.apiDepColombia)
    .pipe(map(res => res || []
    ));
  }

}
