import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractionColorInHexPipe } from './text-color.pipe';
import { BorderColorContractionInHexPipe } from './border-color-contraction-in-hex.pipe';

const pipes = [ContractionColorInHexPipe, BorderColorContractionInHexPipe];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
})
export class PipesModule {}
