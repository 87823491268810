import { Component, Input } from '@angular/core';
import { COLORS } from '../../constants/colors';

@Component({
  selector: 'app-icon',
  template: `
    <mat-icon
      [ngStyle]="{
        height: iconSize,
        width: iconSize,
        'font-size': iconSize,
        color: iconColor
      }"
      >{{ iconName }}
    </mat-icon>
  `,
})
export class IconComponent {
  @Input() iconName: string;
  @Input() iconSize = '22px';
  @Input() iconColor = COLORS.BRAND_BLUE;
}
