import { Component, OnInit, Input } from '@angular/core';
//const BASEURLWHATSAPP = 'https://api.whatsapp.com/send?text=';
const BASEURLWHATSAPP = 'https://wa.me/573193381389?text=';

@Component({
  selector: 'app-simple-call-action',
  templateUrl: './simple-call-action.component.html',
  styleUrls: ['./simple-call-action.component.sass']
})
export class SimpleCallActionComponent implements OnInit {

  hover = false;
  @Input() title: string;
  @Input() colorTitle: string;
  @Input() bgTitle: string;
  @Input() colorCallAction: string;
  @Input() bgAction: string;
  @Input() action: string;
  public wappCount = 1;

  constructor() { }

  ngOnInit(): void {
  }

  styleTitle(): any {
    return {color: this.colorTitle, 'background-color': this.bgTitle};
  }

  messageShare() {
    let message: string;
    message = 'Tengo un cultivo y estoy interesado en comenzar un proyecto con Agrapp, me gustaria saber mas ';
    return message;
  }

  shareWhatsapp() {
    if (this.wappCount === 1) {
      this.wappCount = 2;
    }
    window.open(BASEURLWHATSAPP + this.messageShare());
  }

}
