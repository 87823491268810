import { Pipe, PipeTransform } from '@angular/core';
import { COLORS } from '../constants/colors';

@Pipe({
  name: 'borderColorContractionInHex',
})
export class BorderColorContractionInHexPipe implements PipeTransform {
  transform(color: string): string | boolean {
    return `1px solid ${COLORS[color] || false}`;
  }
}
