import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COLOR_NAMES } from '../../constants/colors';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() label = '';
  @Input() width = '';
  @Input() fontColor = COLOR_NAMES.WHITE;
  @Input() backgroundColor = COLOR_NAMES.BRAND_BLUE;
  @Input() loading = false;
  @Input() height = '29px';
  @Input() fontSize = '14px';
  @Input() disabled = false;
  @Input() addMarginBottom = false;
  @Input() borderColor = '';
  @Input() borderRadius = '8px';
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();
}
