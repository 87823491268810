export class AppSettings {

    public static ownerHubspot = {
        investOwner1: 40101721,
        agronomistOwner1: 43376259,
        agronomistOwner2: 44360524,
        adminOwner1: 40064888,
        adminOwner2: 40101718
    };

    public static typeContact = {
        farmer: 'Agricultores',
        interestedImpact: 'Interesado Impacto social ',
        interestedCovid: 'Interesado Iniciativas',
        interestedInvest: 'Interesado Inversión',
        Investor: 'Inversionista para los proyectos'
    };

    // tslint:disable-next-line: max-line-length
    public static emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    public static navStates = {
        openInvestForm: 'openInvestForm',
        close: 'close'
    }
}