import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api-graphql";


export type ListProjectByPublishQuery = {
  __typename: "ModelProjectConnection";
  items: Array<ProjectItem> | null;
  nextToken: string | null;
};

export enum TransactionState {
  COMPLETED = "COMPLETED",
  PENDING = "PENDING",
  FAILED = "FAILED",
  INPROCESS = "INPROCESS",
  INVALIDATION = "INVALIDATION",
  REJECTED = "REJECTED",
  REFUND = "REFUND"
}

export enum GroupState {
  REQUEST = "REQUEST",
  PUBLICATION = "PUBLICATION",
  IMPLEMENTATION = "IMPLEMENTATION",
  FINALIZED = "FINALIZED",
  CANCELLED = "CANCELLED"
}

export enum ProjectState {
  CONTACT = "CONTACT",
  PREAPPROVAL = "PREAPPROVAL",
  FIELDTRIP = "FIELDTRIP",
  LABORATORY = "LABORATORY",
  EVALUATION = "EVALUATION",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  FUNDED = "FUNDED",
  ESTABLISHMENT = "ESTABLISHMENT",
  VEGETATIVE = "VEGETATIVE",
  PRODUCTION = "PRODUCTION",
  NATURALDISASTER = "NATURALDISASTER",
  RETURNONINVESTMENT = "RETURNONINVESTMENT",
  UNEXPECTED = "UNEXPECTED",
  UNFORESEENMORAL = "UNFORESEENMORAL",
  ECONOMICALLYUNVIABLE = "ECONOMICALLYUNVIABLE",
  TECHNICALLYUNFEASIBLE = "TECHNICALLYUNFEASIBLE",
  WITHDRAWALOFTHEFARMER = "WITHDRAWALOFTHEFARMER",
  FINANCIANGWASNOTCOMPLETED = "FINANCIANGWASNOTCOMPLETED",
  INVALIDATEDBYDOCUMENTS = "INVALIDATEDBYDOCUMENTS"
}

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export enum ResourceType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO"
}

export enum CropPhase {
  ESTABLISHMENT = "ESTABLISHMENT",
  VEGETATIVE = "VEGETATIVE",
  PRODUCTIVE = "PRODUCTIVE"
}

export enum PublishClassifier {
  REQUEST = "REQUEST",
  NEXT = "NEXT",
  PUBLISHED = "PUBLISHED",
  FUNDED = "FUNDED",
  REFUND = "REFUND",
  CANCELLED = "CANCELLED"
}

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  investmentDeadline?: ModelStringInput | null;
  title?: ModelStringInput | null;
  titleEs?: ModelStringInput | null;
  description?: ModelStringInput | null;
  descriptionEs?: ModelStringInput | null;
  locationDescription?: ModelStringInput | null;
  locationDescriptionES?: ModelStringInput | null;
  budgetDescription?: ModelStringInput | null;
  budgetDescriptionES?: ModelStringInput | null;
  fieldSize?: ModelFloatInput | null;
  densityFactor?: ModelFloatInput | null;
  counterpartFarmer?: ModelFloatInput | null;
  totalCost?: ModelFloatInput | null;
  financed?: ModelFloatInput | null;
  investmentGoal?: ModelFloatInput | null;
  minRateFinanced?: ModelFloatInput | null;
  numberPayments?: ModelIntInput | null;
  startPaymentDate?: ModelStringInput | null;
  vegetativePeriod?: ModelIntInput | null;
  productivePeriod?: ModelIntInput | null;
  plantsNumber?: ModelIntInput | null;
  rate?: ModelFloatInput | null;
  optimisticRate?: ModelFloatInput | null;
  pessimisticRate?: ModelFloatInput | null;
  irr?: ModelFloatInput | null;
  latitudeFarm?: ModelFloatInput | null;
  longitudeFarm?: ModelFloatInput | null;
  elevationFarm?: ModelIntInput | null;
  isICA?: ModelBooleanInput | null;
  isExportRegistry?: ModelBooleanInput | null;
  hasFarmerManager?: ModelBooleanInput | null;
  hasProjectBudget?: ModelBooleanInput | null;
  isPublish?: ModelBooleanInput | null;
  hasClosureCause?: ModelBooleanInput | null;
  isPropertyOwner?: ModelBooleanInput | null;
  ownershipType?: ModelOwnerShipTypeInput | null;
  contractType?: ModelContractTypeInput | null;
  initialPhase?: ModelCropPhaseInput | null;
  soilType?: ModelStringInput | null;
  farmerID?: ModelIDInput | null;
  headTownID?: ModelIDInput | null;
  cropID?: ModelIDInput | null;
  projectVisualisationsCount?: ModelIntInput | null;
  currentPublishClassifier?: ModelPublishClassifierInput | null;
  currentStateProjectID?: ModelIDInput | null;
  and?: Array<ModelProjectFilterInput | null> | null;
  or?: Array<ModelProjectFilterInput | null> | null;
  not?: ModelProjectFilterInput | null;
};

export type ModelOwnerShipTypeInput = {
  eq?: OwnerShipType | null;
  ne?: OwnerShipType | null;
};

export type ModelContractTypeInput = {
  eq?: ContractType | null;
  ne?: ContractType | null;
};

export type ModelCropPhaseInput = {
  eq?: CropPhase | null;
  ne?: CropPhase | null;
};

export type ModelPublishClassifierInput = {
  eq?: PublishClassifier | null;
  ne?: PublishClassifier | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelRegionFilterInput = {
  id?: ModelIDInput | null;
  regionName?: ModelStringInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelRegionFilterInput | null> | null;
  or?: Array<ModelRegionFilterInput | null> | null;
  not?: ModelRegionFilterInput | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export enum ProjectDocumentType {
  PROPERTYTITLE = "PROPERTYTITLE",
  LEASINGCONTRACT = "LEASINGCONTRACT",
  TECHNICALASSISTANCECONTRACT = "TECHNICALASSISTANCECONTRACT",
  FUTUREPURCHASECONTRACT = "FUTUREPURCHASECONTRACT",
  PHYSICOCHEMICALSOILANALYSIS = "PHYSICOCHEMICALSOILANALYSIS",
  LANDUSECERTIFICATE = "LANDUSECERTIFICATE",
  PHYSICOCHEMICALWATERANALYSIS = "PHYSICOCHEMICALWATERANALYSIS",
  MICROBIOLOGICALANALYSIS = "MICROBIOLOGICALANALYSIS",
  ICABPACERTIFICATION = "ICABPACERTIFICATION",
  FINANCIALANALYSIS = "FINANCIALANALYSIS",
  PESTMANAGEMENTPLAN = "PESTMANAGEMENTPLAN",
  MAPOFTHEPROPERTY = "MAPOFTHEPROPERTY",
  FERTILIZATIONPLAN = "FERTILIZATIONPLAN",
  DATATREATMENT = "DATATREATMENT",
  CONTRACTWITHTHEFARMER = "CONTRACTWITHTHEFARMER",
  PREAPPROVED = "PREAPPROVED",
  TECHNICALANALYSIS = "TECHNICALANALYSIS"
}

export enum OwnerShipType {
  RENT = "RENT",
  FAMILY = "FAMILY",
  OWN = "OWN",
  OWNWCTL = "OWNWCTL"
}

export enum ContractType {
  NATIONALMARKET = "NATIONALMARKET",
  EXPORTERAGREEMENT = "EXPORTERAGREEMENT",
  AGRICULTURALASSOCIATION = "AGRICULTURALASSOCIATION",
  DOESNOTHAVE = "DOESNOTHAVE",
  EXPORTERWITHOUTAGREEMENT = "EXPORTERWITHOUTAGREEMENT",
  SUPERMARKETS = "SUPERMARKETS"
}

export type GetProjectQuery = {
  __typename: "Project";
  id: string;
  updatedAt: string;
  createdAt: string;
  investmentDeadline: string;
  title: string | null;
  titleEs: string;
  financialProductId: string;
  description: string | null;
  descriptionEs: string;
  locationDescription: string | null;
  locationDescriptionES: string;
  budgetDescription: string | null;
  budgetDescriptionES: string;
  fieldSize: number;
  densityFactor: number;
  counterpartFarmer: number;
  totalCost: number;
  financed: number;
  investmentGoal: number;
  minRateFinanced: number;
  numberPayments: number;
  startPaymentDate: string;
  vegetativePeriod: number;
  productivePeriod: number;
  plantsNumber: number;
  rate: number;
  optimisticRate: number;
  pessimisticRate: number;
  irr: number;
  latitudeFarm: number;
  longitudeFarm: number;
  elevationFarm: number;
  isICA: boolean;
  isExportRegistry: boolean;
  hasFarmerManager: boolean;
  hasProjectBudget: boolean | null;
  isPublish: boolean;
  hasClosureCause: boolean;
  isPropertyOwner: boolean;
  ownershipType: OwnerShipType;
  contractType: ContractType;
  initialPhase: CropPhase;
  soilType: string | null;
  farmerID: string;
  headTownID: string;
  cropID: string;
  projectVisualisationsCount: number;
  currentPublishClassifier: PublishClassifier;
  currentStateProjectID: string;
  currentStateProject: {
    __typename: "ProjectGropState";
    id: string;
    groupState: GroupState;
    projectState: ProjectState;
    publishClassifier: PublishClassifier;
    priority: number | null;
    updatedAt: string | null;
    createdAt: string | null;
  };
  farmerData: {
    __typename: "FarmerData";
    id: string;
    farmerDescription: string | null;
    farmerDescriptionEs: string | null;
    yearsOfExperience: number | null;
    userID: string;
    user: {
      __typename: "User";
      id: string;
      name: string;
      lastName: string;
      profilePhoto: string | null;
    };
    headTownId: string;s
    updatedAt: string;
    createdAt: string;
  };
  headTown: {
    __typename: "HeadTown";
    id: string;
    headTownName: string;
    latitude: number | null;
    longitude: number | null;
    elevation: number | null;
    townID: string;
    town: {
      __typename: "Town";
      id: string;
      townName: string;
      regionID: string;
      region: {
        __typename: "Region";
        id: string;
        regionName: string;
      };
    };
  };
  crop: {
    __typename: "Crop";
    id: string;
    name: string;
    nameEs: string;
    scientificName: string;
    description: string;
    descriptionEs: string;
    picture: string;
    thumbnailsURL: string | null;
    weight: string | null;
    brix: string | null;
    temperture: string | null;
    soilType: string | null;
    soilTypeEs: string | null;
    waterRequirement: string | null;
    pHRange: string | null;
    observations: string | null;
    observationsEs: string | null;
    masl: string | null;
    updatedAt: string | null;
    createdAt: string | null;
  };
  projectHistoricalState: {
    __typename: "ModelProjectHistoricalStateConnection";
    items: Array<{
      __typename: "ProjectHistoricalState";
      id: string;
      projectId: string;
      projectGropStateID: string;
      updatedAt: string;
      createdAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  projectDocument: {
    __typename: "ModelProjectDocumentConnection";
        items: Array<{
      __typename: "ProjectDocument";
      id: string;
      URL: string;
      projectDocumentType: ProjectDocumentType;
      projectID: string;
      updatedAt: string;
      createdAt: string;
  } | null> | null;
    nextToken: string | null;
  } | null;
  projectBudget: {
    __typename: "ModelProjectBudgetConnection";
    nextToken: string | null;
  } | null;
  projectMultimedia: {
    __typename: "ModelProjectMultimediaConnection";
    items: Array<{
      __typename: "ProjectMultimedia";
      id: string;
      URL: string;
      thumbnailsURL: string | null;
      cropPhase: CropPhase;
      projectID: string;
      isPublish: boolean;
      priority: number;
      resourceType: ResourceType;
      updatedAt: string;
      createdAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  projectTransaction: {
    __typename: "ModelProjectTransactionConnection";
    items: Array<{
      __typename: "ProjectTransaction";
      id: string;
      amount: number;
      currentTransactionState: TransactionState;
      updatedAt: string;
      createdAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelTownFilterInput = {
  id?: ModelIDInput | null;
  townName?: ModelStringInput | null;
  regionID?: ModelIDInput | null;
  updatedAt?: ModelStringInput | null;
  createdAt?: ModelStringInput | null;
  and?: Array<ModelTownFilterInput | null> | null;
  or?: Array<ModelTownFilterInput | null> | null;
  not?: ModelTownFilterInput | null;
};

export type ListtownByRegionQuery = {
  __typename: "ModelTownConnection";
  items: Array<{
    __typename: "Town";
    id: string;
    townName: string;
    regionID: string;
    updatedAt: string | null;
    createdAt: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListRegionsQuery = {
  __typename: "ModelRegionConnection";
  items: Array<{
    __typename: "Region";
    id: string;
    regionName: string;
    updatedAt: string | null;
    createdAt: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type ListProjectByMap = {
  __typename: "ModelProjectConnection";
  items: Array<ProjectItem> | null;
  nextToken: string | null;
};

export type ProjectItem = {
  __typename: "Project";
  id: string;
  updatedAt: string;
  createdAt: string;
  investmentDeadline: string;
  title: string | null;
  titleEs: string;
  financialProductId: string;
  counterpartFarmer: number;
  totalCost: number;
  financed: number;
  investmentGoal: number;
  minRateFinanced: number;
  numberPayments: number;
  startPaymentDate: string;
  rate: number;
  irr: number;
  isPublish: boolean;
  currentPublishClassifier: PublishClassifier;
  farmerID: string;
  headTownID: string;
  cropID: string;
  currentStateProjectID: string;
  currentStateProject: {
    __typename: "ProjectGropState";
    id: string;
    groupState: GroupState;
    projectState: ProjectState;
    publishClassifier: PublishClassifier;
    priority: number | null;
    updatedAt: string | null;
    createdAt: string | null;
  };
  farmerData: {
    __typename: "FarmerData";
    id: string;
    farmerDescription: string | null;
    farmerDescriptionEs: string | null;
    yearsOfExperience: number | null;
    userID: string;
    user: {
      _typename: "User";
      id: string;
      name: string;
      lastName: string;
      phone: string;
      email: string;
      isPolicies: boolean;
      documentNumber: string;
      countryID: string;
      updatedAt: string;
      createdAt: string;
      profilePhoto: string | null;
    }
    headTownId: string;
    updatedAt: string;
    createdAt: string;
  };
  headTown: {
    __typename: "HeadTown";
    id: string;
    headTownName: string;
    latitude: number | null;
    longitude: number | null;
    elevation: number | null;
    townID: string;
    town: {
      __typename: "Town";
      id: string;
      townName: string;
      regionID: string;
      region: {
        __typename: "Region";
        id: string;
        regionName: string;
        updatedAt: string | null;
        createdAt: string | null;
      };
      towns: {
        __typename: "ModelHeadTownConnection";
        nextToken: string | null;
      } | null;
      updatedAt: string | null;
      createdAt: string | null;
    };
    updatedAt: string | null;
    createdAt: string | null;
  };
  crop: {
    __typename: "Crop";
    id: string;
    name: string;
    nameEs: string;
    description: string;
    descriptionEs: string;
    picture: string;
    scientificName: string;
    updatedAt: string | null;
    createdAt: string | null;
    brix: string;
    masl: string;
    observations: string;
    observationsEs: string;
    pHRange: string;
    soilType: string;
    soilTypeEs: string;
    temperture: string;
    thumbnailsURL: string;
    waterRequirement: string;
    weight: string;
  };
  projectHistoricalState: {
    __typename: "ModelProjectHistoricalStateConnection";
    items: Array<{
      __typename: "ProjectHistoricalState";
      id: string;
      projectId: string;
      projectGropStateID: string;
      projectGropState: {
        __typename: "ProjectGropState";
        id: string;
        groupState: GroupState;
        projectState: ProjectState;
        updatedAt: string | null;
        createdAt: string | null;
      };
      updatedAt: string;
      createdAt: string;
    } | null> | null;
    nextToken: string | null;
  };
  projectDocument: {
    __typename: "ModelProjectDocumentConnection";
    nextToken: string | null;
  } | null;
  projectMultimedia: {
    __typename: "ModelProjectMultimediaConnection";
    items: Array<{
      __typename: "ProjectMultimedia";
      id: string;
      URL: string;
      thumbnailsURL: string;
      cropPhase: CropPhase;
      projectID: string;
      isPublish: boolean;
      resourceType: ResourceType;
      updatedAt: string;
      createdAt: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
  projectTransaction: {
    __typename: "ModelProjectTransactionConnection";
    items: Array<{
      __typename: "ProjectTransaction";
      id: string;
      amount: number;
      investmentCertificationURL: string | null;
      transactionVoucherURL: string | null;
      refoundVoucherURL: string | null;
      currentTransactionState: TransactionState;
      updatedAt: string;
      createdAt: string;
      projectID: string;
      investorID: string;
    } | null> | null;
    nextToken: string | null;
  } | null;
};

export type ProjectItemMap = {
  __typename: "Project";
  id: string;
  title: string | null;
  titleEs: string;
  farmerID: string;
  headTownID: string;
  farmerData: {
    __typename: "FarmerData";
    id: string;
    farmerDescription: string | null;
    farmerDescriptionEs: string | null;
    userID: string;
    user: {
      _typename: "User";
      id: string;
      name: string;
      lastName: string;
      profilePhoto: string | null;
    }
    headTownId: string;
    updatedAt: string;
    createdAt: string;
  };
  headTown: {
    __typename: "HeadTown";
    id: string;
    headTownName: string;
    latitude: number | null;
    longitude: number | null;
    elevation: number | null;
    townID: string;
    town: {
      __typename: "Town";
      id: string;
      townName: string;
      regionID: string;
      region: {
        __typename: "Region";
        id: string;
        regionName: string;
        updatedAt: string | null;
        createdAt: string | null;
      };
      towns: {
        __typename: "ModelHeadTownConnection";
        nextToken: string | null;
      } | null;
      updatedAt: string | null;
      createdAt: string | null;
    };
    updatedAt: string | null;
    createdAt: string | null;
  };
};

@Injectable({
  providedIn: 'root'
})
export class ProjectListService {

  async ListProjectsByPublish(
    currentPublishClassifier?: PublishClassifier,
    createdAt?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelProjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProjectByPublishQuery> {
    const statement = `query ListProjectByPublish($currentPublishClassifier: PublishClassifier, $createdAt: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelProjectFilterInput, $limit: Int, $nextToken: String) {
      listProjectByPublish(currentPublishClassifier: $currentPublishClassifier, createdAt: $createdAt, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            updatedAt
            createdAt
            investmentDeadline
            title
            titleEs
            financialProductId
            counterpartFarmer
            totalCost
            financed
            investmentGoal
            minRateFinanced
            numberPayments
            startPaymentDate
            rate
            irr
            isPublish
            currentPublishClassifier
            farmerID
            headTownID
            cropID
            currentStateProjectID
            currentStateProject {
              __typename
              id
              groupState
              projectState
              publishClassifier
              priority
              updatedAt
              createdAt
            }
            farmerData {
              __typename
              id
              farmerDescription
              farmerDescriptionEs
              yearsOfExperience
              user {
                name
                lastName
                updatedAt
                createdAt
                profilePhoto
              }
              headTownId
              updatedAt
              createdAt
            }
            headTown {
              __typename
              id
              headTownName
              latitude
              longitude
              elevation
              townID
              town {
                __typename
                id
                townName
                regionID
                region {
                  __typename
                  id
                  regionName
                  updatedAt
                  createdAt
                }
                towns {
                  __typename
                  nextToken
                }
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
            }
            crop {
              __typename
              id
              name
              nameEs
              description
              descriptionEs
              picture
              scientificName
              updatedAt
              createdAt
              brix
              masl
              observations
              observationsEs
              pHRange
              soilType
              soilTypeEs
              temperture
              thumbnailsURL
              waterRequirement
              weight
            }
            projectHistoricalState {
              __typename
              items {
                __typename
                id
                projectId
                projectGropStateID
                projectGropState {
                  __typename
                  id
                  groupState
                  projectState
                  updatedAt
                  createdAt
                }
                updatedAt
                createdAt
              }
              nextToken
            }
            projectDocument {
              __typename
              nextToken
            }
            projectMultimedia {
              items {
                __typename
                id
                URL
                thumbnailsURL
                cropPhase
                projectID
                isPublish
                resourceType
                updatedAt
                createdAt
              }
              nextToken
            }
            projectTransaction {
              items {
                __typename
                id
                amount
                currentTransactionState
                updatedAt
                createdAt
                projectID
              }
            }
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (currentPublishClassifier) {
      gqlAPIServiceArguments.currentPublishClassifier = currentPublishClassifier;
    }
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProjectByPublishQuery>response.data.listProjectByPublish;
  }

  async ListProjects(
    filter?: ModelProjectFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProjectByMap> {
    const statement = `query ListProjects($filter: ModelProjectFilterInput, $limit: Int, $nextToken: String) {
        listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            title
            titleEs
            farmerID
            headTownID
            farmerData {
              __typename
              id
              farmerDescription
              farmerDescriptionEs
              yearsOfExperience
              user {
                name
                lastName
                countryID
                updatedAt
                createdAt
                profilePhoto
              }
              headTownId
              updatedAt
              createdAt
            }
            headTown {
              __typename
              id
              headTownName
              latitude
              longitude
              elevation
              townID
              town {
                __typename
                id
                townName
                regionID
                region {
                  __typename
                  id
                  regionName
                  updatedAt
                  createdAt
                }
                towns {
                  __typename
                  nextToken
                }
                updatedAt
                createdAt
              }
              updatedAt
              createdAt
            }  
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProjectByMap>response.data.listProjects;
  }

  async Decrypt(text?: string): Promise<string | null> {
    const statement = `mutation Decrypt($text: String) {
        decrypt(text: $text)
      }`;
    const gqlAPIServiceArguments: any = {};
    if (text) {
      gqlAPIServiceArguments.text = text;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <string | null>response.data.decrypt;
  }

  async ListtownByRegion(
    regionID?: string,
    townName?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelTownFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListtownByRegionQuery> {
    const statement = `query ListtownByRegion($regionID: ID, $townName: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelTownFilterInput, $limit: Int, $nextToken: String) {
        listtownByRegion(regionID: $regionID, townName: $townName, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            townName
            regionID
            updatedAt
            createdAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (regionID) {
      gqlAPIServiceArguments.regionID = regionID;
    }
    if (townName) {
      gqlAPIServiceArguments.townName = townName;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListtownByRegionQuery>response.data.listtownByRegion;
  }

  async ListRegions(
    filter?: ModelRegionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListRegionsQuery> {
    const statement = `query ListRegions($filter: ModelRegionFilterInput, $limit: Int, $nextToken: String) {
        listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            regionName
            updatedAt
            createdAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListRegionsQuery>response.data.listRegions;
  }
  async GetProject(id: string): Promise<GetProjectQuery> {
    const statement = `query GetProject($id: ID!) {
        getProject(id: $id) {
          __typename
          id
          updatedAt
          createdAt
          investmentDeadline
          title
          titleEs
          financialProductId
          description
          descriptionEs
          locationDescription
          locationDescriptionES
          budgetDescription
          budgetDescriptionES
          fieldSize
          densityFactor
          counterpartFarmer
          totalCost
          financed
          investmentGoal
          minRateFinanced
          numberPayments
          startPaymentDate
          vegetativePeriod
          productivePeriod
          plantsNumber
          rate
          optimisticRate
          pessimisticRate
          irr
          latitudeFarm
          longitudeFarm
          elevationFarm
          isICA
          isExportRegistry
          hasFarmerManager
          hasProjectBudget
          isPublish
          hasClosureCause
          isPropertyOwner
          ownershipType
          contractType
          initialPhase
          soilType
          farmerID
          headTownID
          cropID
          projectVisualisationsCount
          currentPublishClassifier
          currentStateProjectID
          currentStateProject {
            __typename
            id
            groupState
            projectState
            publishClassifier
            priority
            updatedAt
            createdAt
          }
          farmerData {
            __typename
            id
            farmerDescription
            farmerDescriptionEs
            yearsOfExperience
            user {
              __typename
              name
              lastName
              profilePhoto
            }
            headTownId
            updatedAt
            createdAt
          }
          headTown {
            __typename
            id
            headTownName
            latitude
            longitude
            elevation
            townID
            town {
              __typename
              id
              townName
              regionID
              region {
                __typename
                id
                regionName
              }
            }
          }
          crop {
            __typename
            id
            name
            nameEs
            scientificName
            description
            descriptionEs
            picture
            thumbnailsURL
            weight
            brix
            temperture
            soilType
            soilTypeEs
            waterRequirement
            pHRange
            observations
            observationsEs
            masl
            updatedAt
            createdAt
          }
          projectHistoricalState {
            __typename
            items {
              __typename
              id
              projectId
              projectGropStateID
              updatedAt
              createdAt
            }
            nextToken
          }
          projectDocument {
            __typename
            items {
              __typename
              id
              projectDocumentType
              projectID
              updatedAt
              createdAt
            }
            nextToken
          }
          projectBudget {
            __typename
            nextToken
          }
          projectMultimedia {
            __typename
            items {
              __typename
              id
              URL
              thumbnailsURL
              cropPhase
              projectID
              isPublish
              priority
              resourceType
              updatedAt
              createdAt
            }
            nextToken
          }
          projectTransaction {
            __typename
            items {
              __typename
              amount
              currentTransactionState
              updatedAt
              createdAt
            }
            nextToken
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProjectQuery>response.data.getProject;
  }

}
