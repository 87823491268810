import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EmitData } from '../models/emitData';



@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  observer = new Subject();
  public subscriber$ = this.observer.asObservable();
  data: EmitData;

  observerMenu = new Subject();
  public subscriberMenu = this.observerMenu.asObservable();

  emitNav(key: string, extraData: any) {
    this.data = new EmitData();
    this.data.key = key;
    this.data.extraData = extraData;
    this.observer.next(this.data);
  }

  emitMenuLogout(status: string){
    this.observerMenu.next(status);
  }
}
