import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, FormControl, Validator, AbstractControl, ValidatorFn } from '@angular/forms';

export function forbiddenNameValidator(nameRe: RegExp): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const forbidden = nameRe.test(control.value);
        return forbidden ? { 'forbiddenName': { value: control.value } } : null;
    };
}

export function comparatePassword(confirm): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const pass = control.value;
        const forbidden1 = pass.trim().length > 0;
        const forbidden2 = confirm.trim().length > 0;
        const isEmpy = !(pass.trim().length > 0);
        return forbidden1 && forbidden2 ? { 'isSame': { value: ((pass === confirm) && !isEmpy) } } : null;
    };
}

// Todo valid passwor size and charts
// Todo rex email complete formater

@Directive({
    selector: '[appForbiddenName]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CustomValidator, multi: true }]
})
export class CustomValidator implements Validator {
    @Input('appForbiddenName') forbiddenName: string;

    validate(control: AbstractControl): { [key: string]: any } | null {
        return this.forbiddenName ? forbiddenNameValidator(new RegExp(this.forbiddenName, 'i'))(control)
            : null;
    }
}
