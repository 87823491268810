import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderV2Component } from './header-v2/header-v2.component';
import { FooterV2Component } from './footer-v2/footer-v2.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { MatIconModule } from '@angular/material/icon';
import { MatRippleModule } from '@angular/material/core';
import { SideMenuV2Component } from './side-menu-v2/side-menu-v2.component';
import { PipesModule } from '../pipes/pipes.module';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';

const components = [HeaderV2Component, FooterV2Component, SideMenuV2Component];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    SharedComponentsModule,
    MatIconModule,
    MatRippleModule,
    PipesModule,
    MatListModule,
    RouterModule,
  ],
  exports: [...components],
})
export class LayoutV2Module {}
