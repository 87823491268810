import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-arrows-to-scroll-horizontally',
  templateUrl: './arrows-to-scroll-horizontally.component.html',
  styleUrls: ['./arrows-to-scroll-horizontally.component.scss'],
})
export class ArrowsToScrollHorizontallyComponent {
  @Input() isScrollableLeft: boolean;
  @Input() isScrollableRight: boolean;
  @Output() scrollLeft: EventEmitter<void> = new EventEmitter();
  @Output() scrollRight: EventEmitter<void> = new EventEmitter();
}
