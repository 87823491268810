import { FooterTab } from '../interfaces/footer-tab';

export const legalFooterTabs: FooterTab[] = [
  {
    label: 'Política de privacidad de datos',
    route:
      'https://agrappfinancial.s3.amazonaws.com/politicaTratamientoDatosAgrapp.pdf',
    isExternal: true,
  },
  {
    label: 'Información legal',
    route: '/legal',
  },
  {
    label: 'Preguntas frecuentes',
    route: '/preguntas',
  },
];

export const aboutUsFooterTabs: FooterTab[] = [
  {
    label: 'Invierte',
    route: '/invierte',
  },
  {
    label: 'Productores',
    route: '/agricultores',
  },
  {
    label: 'Comunidad',
    route: '/comunidad',
  },
  {
    label: 'Contáctanos',
    route: '/contact',
  },
  {
    label: 'Aprende',
    route: '/aprende',
  },
];

export const contactUsFooterTabs: FooterTab[] = [
  {
    label: 'Calle 71 #10-40 Oficina 501',
    icon: 'location_on',
  },
  {
    label: 'servicioalcliente@agrapp.co',
    icon: 'mail',
  },
  {
    label: '+57 319 3381389',
    icon: 'call',
  },
];

export const socialMediaFooterIcons: FooterTab[] = [
  {
    label: 'assets/icons/facebook.svg',
    route: 'https://www.facebook.com/agrapp.co/',
    isExternal: true,
  },
  {
    label: 'assets/icons/linkedin.svg',
    route: 'https://www.linkedin.com/company/agrappcolombia/',
    isExternal: true,
  },
  {
    label: 'assets/icons/youtube.svg',
    route: 'https://www.youtube.com/c/AgrappCOL',
    isExternal: true,
  },
];
