import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.sass']
})
export class SubscriptionComponent implements OnInit, OnChanges  {

  @Input() bgColor: string;
  @Input() title: string;
  @Input() colorTitle: string;
  @Input() inputText: string;
  @Input() errorMsg: string;
  @Input() callAction: string;
  @Input() colorButton: string;
  @Input() colorTextButton: string;
  @Input() info: string;
  @Input() updated: string;

  @ViewChild('f') myNgForm;

  subsForm = new FormGroup({});

  // tslint:disable-next-line: ban-types
  @Output() subToSend = new EventEmitter<Object>();
  public emailToSend = null;

  constructor( ) { }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'updated': {
            if (this.updated === 'ready') {
              this.myNgForm.resetForm();
            }
          }
        }
      }
    }
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.subsForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.email
      ])
    });
  }

  subscribe(subsForm) {
    if (this.subsForm.valid) {
      this.emailToSend = this.subsForm.get('email').value;
      this.subToSend.emit({value: this.emailToSend});
    }
  }

}
