
import { ProjectListService } from './../../services/project-list.service';
import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import * as Proj from 'ol/proj';
import { defaults as defaultControls } from 'ol/control';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon, Style, Fill, Stroke } from 'ol/style.js';
import VectorSource from 'ol/source/Vector.js';
import VectorLayer from 'ol/layer/Vector';

import 'ol/ol.css';
import XYZ from 'ol/source/XYZ';
import Polygon from 'ol/geom/Polygon';
import { ColombiaService } from 'src/app/services/colombia.service';
import { GoogleAnalyticsEventsService } from 'src/app/services/google-analytics-events.service';
import { Router } from '@angular/router';

export const DEFAULT_HEIGHT = '500px';
export const DEFAULT_WIDTH = '500px';

export class ObjectDepartments {
  coordinates: Array<any>;
}
declare var ol: any;

@Component({
  selector: 'app-map-interactive',
  templateUrl: './map-interactive.component.html',
  styleUrls: ['./map-interactive.component.sass']
})
export class MapInteractiveComponent implements OnInit {

  @Input() lat: number;
  @Input() lon: number;
  @Input() zoom: number;
  @Input() width: string | number = DEFAULT_WIDTH;
  @Input() height: string | number = DEFAULT_HEIGHT;
  public bogota;
  public cauca;
  public cartagena;
  public vectorSource;
  public vectorLayer;
  public rasterLayer;
  public layers;
  public tileLayerB;
  public departments = [];
  public temp;
  public pointTransformB;
  public coordinatesPolygonB;
  public source;
  public vector;
  public depArray;
  public plygon;
  public feature;
  public respDep;
  public imgMap;
  public nameMap;
  public lastnameMap;
  public locationMap;
  public idCurrentProject;
  public descMap;
  projects: any = [];
  public arrayPoints: Array<Feature> = [];
  map: Map;
  public wappCount = 1;
  public highlightStyle;

  private mapEl: HTMLElement;
  constructor(
    private elementRef: ElementRef,
    private colService: ColombiaService,
    private api: ProjectListService,
    private router: Router,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
  ) {
    this.api.ListProjects()
      .then(event => {
        this.projects = event.items;
        this.projects = this.projects.filter(function (item) {
          return item.farmerData.farmerDescriptionEs.length > 3;
        }).map(function (item) {
          item["farmerData"]["farmerDescriptionEs"] = item.farmerData.farmerDescriptionEs.substring(0, 520) + " ..";
          return item;
        });
        let startIndex = Math.floor(Math.random() * ((this.projects.length - 1)));
        this.imgMap = this.projects[startIndex].farmerData.user.profilePhoto;
        this.locationMap = this.projects[startIndex].headTown.headTownName + ', ' + this.projects[startIndex].headTown.town.region.regionName;
        this.descMap = this.projects[startIndex].farmerData.farmerDescriptionEs;
        this.idCurrentProject = this.projects[startIndex].id;
        this.drawMap();
      });
  }

  ngOnInit(): void {
    this.mapEl = this.elementRef.nativeElement.querySelector('#map');
  }

  drawMap() {
    this.colService.getCoordinates().subscribe(res => {
      this.respDep = res;
      for (const i of this.respDep.features) {
        this.temp = i.geometry.coordinates;
        this.coordinatesPolygonB = [];
        this.temp.forEach((m) => {
          this.pointTransformB = Proj.fromLonLat(m);
          this.coordinatesPolygonB.push(this.pointTransformB);
        });
        this.departments.push(this.coordinatesPolygonB);
      }
      this.source = new VectorSource();
      this.vector = new VectorLayer({
        source: this.source,
        style: new Style({
          stroke: new Stroke({
            color: '#0099FF',
            lineDash: [0],
            width: 3
          }),
          fill: new Fill({
            color: 'rgba(0, 153, 255, 0.2)'
          })
        })
      });
      this.depArray = [];
      for (const x of this.departments) {
        this.depArray.push(x);
      }
      this.plygon = new Polygon(this.depArray);
      this.feature = new Feature({
        geometry: this.plygon
      });
      this.source.addFeature(this.feature);
      // Tile Layer
      this.tileLayerB = new TileLayer({
        source: new XYZ({
          // tslint:disable-next-line: max-line-length
          url: 'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2FybG9zYWdyYXBwIiwiYSI6ImNqZ2xldmIxODEyb24yd3F1YTE0ZDB0MG4ifQ.CSpVrZ6pTMIRQ--JqbmxqA'
        })
      });

      this.highlightStyle = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          src: '../../../assets/icons/geopoint-white.png',
          imgSize: [40, 40]
        })
      });
      const unselected = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          src: '../../../assets/icons/geopoint-black.png',
          imgSize: [40, 40]
        })
      });
      const selected = null;
      for (const project of this.projects) {


        const k = new Feature({
          type: 'click',
          img: project.farmerData.user.profilePhoto,
          name: project.farmerData.user.name,
          lastname: project.farmerData.user.lastName,
          location: project.headTown.headTownName + ', ' + project.headTown.town.region.regionName,
          desc: project.farmerData.farmerDescriptionEs,
          id: project.id,
          geometry: new Point(Proj.fromLonLat([project.headTown.longitude, project.headTown.latitude]))
        });

        k.setStyle(unselected);
        this.arrayPoints.push(k);
      }
      this.arrayPoints[3].setStyle(this.highlightStyle);
      for (const agrappPoint of this.arrayPoints) {
        this.source.addFeature(agrappPoint);
      }
      ////////////////////////////////////
      const map = new Map({
        target: 'map',
        layers: [this.tileLayerB, this.vector],
        view: new View({
          center: Proj.fromLonLat([this.lon, this.lat]),
          zoom: this.zoom
        }),
        controls: defaultControls().extend([]),
        interactions: [],
      });

      map.on('click', (evt) => {

        for (const agrappPoint of this.arrayPoints) {
          agrappPoint.setStyle(unselected);
        }

        const lonlat = Proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
        const lon = lonlat[0];
        const lat = lonlat[1];
        const featureB = map.forEachFeatureAtPixel(evt.pixel, (feature: Feature) => {
          return feature;
        });

        let farmerName = "";
        let farmerLastname = "";

        this.api.Decrypt(featureB.get('name')).then((event) => {
          farmerName = event.split("body=")[1].replace("}", "");
          this.api.Decrypt(featureB.get('lastname')).then((event) => {
            farmerLastname = event.split("body=")[1].replace("}", "");
            if (featureB && featureB.get('type') === 'click') {
              featureB.setStyle(this.highlightStyle);
              this.imgMap = featureB.get('img');
              this.nameMap = farmerName
              this.lastnameMap = farmerLastname;
              this.locationMap = featureB.get('location');
              this.descMap = featureB.get('desc');
              this.idCurrentProject = featureB.get('id')
            }
          })
        })
      });
    });
  }

  private setSize(): void {
    if (this.mapEl) {
      const styles = this.mapEl.style;
      styles.height = coerceCssPixelValue(this.height) || DEFAULT_HEIGHT;
    }
  }

  sendAnalytics(category, label, value?, event?) {
    this.googleAnalyticsEventsService.emitEvent(category, event, label, value);
    //this.googleAnalyticsEventsService.emitEvent('Homepage', 'time', "forbers", value);
  }

  messageShare() {
    let message: string;
    message = 'Quiero conocer más sobre los proyectos disponibles para inversión ';
    return message;
  }

  openProject() {
    this.router.navigateByUrl('/proyectos/' + this.idCurrentProject);
  }
}

const cssUnitsPattern = /([A-Za-z%]+)$/;

function coerceCssPixelValue(value: any): string {
  if (value == null) {
    return '';
  }
  return cssUnitsPattern.test(value) ? value : `${value}px`;
}


