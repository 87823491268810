import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InvestorContact } from 'src/app/models/investor-contact';
import { GoogleAnalyticsEventsService } from 'src/app/services/google-analytics-events.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared-components/alert-dialog/alert-dialog.component';
import { ResponseHubspot } from 'src/app/models/responseHubspot';
import { AppSettings } from 'src/app/app-settings';
import { HubspotService } from 'src/app/services/hubspot.service';

@Component({
  selector: 'app-subs-initiatives',
  templateUrl: './subs-initiatives.component.html',
  styleUrls: ['./subs-initiatives.component.sass']
})
export class SubsInitiativesComponent implements OnInit {

  @Input() mainColor: string;
  formInitiative = new FormGroup({});
  public userArray: InvestorContact;
  public contactReg: InvestorContact;
  public textAlertEs: string;
  public textAlertEn: string;
  public colorText: string;
  public widthSize: string;
  public heightSize: string;
  public updated = null;

  @ViewChild('formI') myNgForm;

  constructor(
    private hubspotService: HubspotService,
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.formInitiative = new FormGroup({
      name: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('')
    });
    this.userArray = new InvestorContact();
  }

  onSubmit() {
    if (this.formInitiative.valid) {
      this.prepareData(this.formInitiative);
    }
  }

  prepareData(formInitiative) {
    this.userArray.name = formInitiative.get('name').value;
    this.userArray.lastName = formInitiative.get('lastname').value;
    this.userArray.email = formInitiative.get('email').value;
    this.userArray.know = 'Iniciativas';
    this.userArray.message = formInitiative.get('message').value;
    this.userArray.owner = AppSettings.ownerHubspot.investOwner1;
    this.userArray.typeContact = AppSettings.typeContact.interestedCovid;
    this.hubspotService
    .saveInvestorHubspot(this.userArray)
    .subscribe((resp: ResponseHubspot) => {
      if (resp.code === 200) {
        this.openDialog(resp.data.email);
        this.sendAnalytics('subscription', 'email_subscription_initiativesPage', '', 'send');
      }
      this.myNgForm.resetForm();
    });
  }

  openDialog(email: string) {
    this.textAlertEs = 'Te mantendremos informados de las iniciativas que apoyan a los héroes del campo colombiano. ';
    this.textAlertEn = 'We will keep you informed of the initiatives that support the heroes of the Colombian countryside.';
    this.colorText = '#000';
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      height: '380px',
      width: '320px',
      panelClass: 'custom-modalbox',
      data: {
        textAlertEs: this.textAlertEs,
        textAlertEn: this.textAlertEn,
        colorText: this.colorText
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.updated = true;
    });
  }

  sendAnalytics(category, label, value?, event?) {
    this.googleAnalyticsEventsService.emitEvent(category, event, label, value);
  }

}
