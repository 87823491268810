import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner-invest',
  templateUrl: './banner-invest.component.html',
  styleUrls: ['./banner-invest.component.sass']
})
export class BannerInvestComponent implements OnInit {

  @Input() color = ''
  constructor() { }

  ngOnInit(): void {
  }

}
