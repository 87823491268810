import { Component, EventEmitter, Output } from '@angular/core';
import { HEADER_ITEMS, HeaderItem } from '../../constants';
import { Router } from '@angular/router';
import { UTILS } from '../../constants/utils';

@Component({
  selector: 'app-side-menu-v2',
  templateUrl: './side-menu-v2.component.html',
  styleUrls: ['./side-menu-v2.component.scss'],
})
export class SideMenuV2Component {
  @Output() closeSideMenu: EventEmitter<void> = new EventEmitter<void>();
  public readonly MENU_ITEMS: HeaderItem[] = HEADER_ITEMS;

  constructor(private router: Router) {}

  public async goToPage(route: string) {
    await this.router.navigate([route]);
    this.closeSideMenu.emit();
  }

  public openAccount() {
    window.open(UTILS.SIGNUP, '_self');
  }

  public openLogin() {
    window.open(UTILS.LOGIN, '_self');
  }
}
