import { Component, EventEmitter, Output } from '@angular/core';
import { BreakpointsObserverService } from '../../services/breakpoints-observer.service';
import { HEADER_ITEMS, HeaderItem } from '../../constants';
import { UTILS } from '../../constants/utils';

@Component({
  selector: 'app-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss'],
})
export class HeaderV2Component {
  @Output() showSideMenuIconClick: EventEmitter<void> =
    new EventEmitter<void>();
  public readonly HEADER_ITEMS: HeaderItem[] = HEADER_ITEMS;

  constructor(public breakpointObserverService: BreakpointsObserverService) {}

  public openAccount() {
    window.open(UTILS.SIGNUP, '_self');
  }

  public openLogin() {
    window.open(UTILS.LOGIN, '_self');
  }
}
