import { VideoDialogComponent } from './../video-dialog/video-dialog.component';
import { Component, OnInit, HostListener, ViewChild, Input } from '@angular/core';
import { ImagesCond } from 'src/app/models/images-cond';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { User } from 'src/app/models/user';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-interactive-testimony',
  templateUrl: './interactive-testimony.component.html',
  styleUrls: ['./interactive-testimony.component.sass']
})
export class InteractiveTestimonyComponent implements OnInit {

  picture: string;
  innerWidth: number;
  slideConfigPartners: any;
  elementsScreen: number;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  sizeIcon: string;
  @Input() title: string;
  @Input() colorSection: string;
  @Input() users: Array<User>;
  public borderColor;

  constructor( public dialog: MatDialog) {
   }

  ngOnInit() {
    this.borderColor = '2px solid' + this.colorSection;
    this.carouselFunction();
    this.checkSize();
  }

  carouselFunction() {
    this.innerWidth = window.innerWidth;
    this.elementsScreen = 1;
    this.slideConfigPartners = {
      slidesToShow: this.elementsScreen,
      slidesToScroll: this.elementsScreen,
      enabled: true,
      autoplay: true,
      draggable: true,
      dots: false,
      initialSlide: 0,
      infinite: true,
      autoplaySpeed: 4500,
      arrows: false,
    };
  }

  next() {
    this.slickModal.slickNext();
  }

  prev() {
    this.slickModal.slickPrev();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.carouselFunction();
    this.checkSize();
  }

  checkSize() {
    if (this.innerWidth < 600) {
      this.sizeIcon = '2x';
    } else {
      this.sizeIcon = '3x';
    }
  }

  seeVideo(index: number) {
    // console.log('index ' + index);
    const video = this.users[index].videoUrl;
    // console.log(video);
    const dialogRef = this.dialog.open(VideoDialogComponent, {
      height: '510px',
      width: '680px',
      panelClass: 'custom-modalbox',
      data: {
        video
      }
    });
  }

}
