import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-hero',
  templateUrl: './main-hero.component.html',
  styleUrls: ['./main-hero.component.sass']
})
export class MainHeroComponent implements OnInit, AfterViewInit {

  @Input() picture: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() opacity: number;
  @Input() color: string;
  @Input() button: string;
  @Input() linkAction: string;
  @Input() buttonColor: string;
  // tslint:disable-next-line: ban-types
  @Output() buttonClick = new EventEmitter<Object>();

  @ViewChild('image', {static: false}) image: ElementRef;
  @ViewChild('capTitle', {static: false}) capTitle: ElementRef;
  @ViewChild('capSubtitle', {static: false}) capSubtitle: ElementRef;
  @ViewChild('capButton', {static: false}) capButton: ElementRef;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.image.nativeElement.style.opacity = this.opacity;
    this.capTitle.nativeElement.style.color = this.color;
    this.capSubtitle.nativeElement.style.color = this.color;
    this.capButton.nativeElement.style.color = this.buttonColor;
    this.capButton.nativeElement.style.background = this.color;
  }

  actionClick() {
    this.buttonClick.emit({value: true});
  }

}
