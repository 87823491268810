import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsEventsService } from 'src/app/services/google-analytics-events.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  content = {
    urlPolice: 'https://agrappfinancial.s3.amazonaws.com/politicaTratamientoDatosAgrapp.pdf',
    direction: 'Carrera 4a #53-15| BOGOTÁ',
    emailContact: 'proyectos@agrapp.co',
    emailContactInvest: 'inversionistas@agrapp.co',
    mainPhone: '+57 319 3381389',
    social: {
      facebook: 'https://www.facebook.com/agrapp.co/',
      linkedin: 'https://www.linkedin.com/company/33274271/',
      instagram: 'https://www.instagram.com/agrapp.co/',
      youtube: 'https://www.youtube.com/c/AgrappCOL',
      twitter: 'https://twitter.com/agrapp_co',
      googleplus: 'https://plus.google.com/111384450969796537441?hl=es'
    }
  };

  colorFont: string;

  constructor(
    public googleAnalyticsEventsService: GoogleAnalyticsEventsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe(val => {
     if (this.router.url.includes('invierte') || this.router.url.includes('proyectos')) {
        this.colorFont = '#FF9933';
      } else if (this.router.url.includes('agricultores')) {
        this.colorFont = '#33CC66';
      } else {
        this.colorFont = '#00AEEC';
      }
    });
  }
  clickLink(category, label) {
    this.sendAnalytics(category, label, 1, 'click');
  }

  sendAnalytics(category, label, value?, event?) {
    this.googleAnalyticsEventsService.emitEvent(category, event, label, value);
  }

}
