import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from '@angular/core';
import { AnimateNumbersComponent } from '../animate-numbers/animate-numbers.component';

@Component({
  selector: 'app-community-metrics',
  templateUrl: './community-metrics.component.html',
  styleUrls: ['./community-metrics.component.sass'],
})
export class CommunityMetricsComponent implements AfterViewInit {
  @ViewChild('panelMetrics') panelMetrics!: ElementRef;
  @ViewChild('childMetrics1') childMetrics1!: AnimateNumbersComponent;
  @ViewChild('childMetrics2') childMetrics2!: AnimateNumbersComponent;
  @ViewChild('childMetrics3') childMetrics3!: AnimateNumbersComponent;
  @ViewChild('childMetrics4') childMetrics4!: AnimateNumbersComponent;

  panelMetricsPosition = 0;
  isAnimateMetrics = false;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.panelMetrics) {
      this.panelMetricsPosition = this.panelMetrics.nativeElement.offsetTop;
    }
    // this.launchAnimation();
    // console.log("scroll panel config: ",this.panelMetrics.nativeElement.offsetTop);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll($event: Event) {
    this.launchAnimation();
  }

  launchAnimation() {
    // console.log("scroll Windows: ", window.pageYOffset);
    // console.log("scroll Panel: ", this.panelMetricsPosition);
    if (!this.isAnimateMetrics) {
      if (window.pageYOffset > this.panelMetricsPosition) {
        this.childMetrics1.startAnimation();
        this.childMetrics2.startAnimation();
        this.childMetrics3.startAnimation();
        this.childMetrics4.startAnimation();
        this.isAnimateMetrics = true;
      }
    }
  }
}
