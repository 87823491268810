import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPageScrollModule } from 'ngx-page-scroll';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MaterialModule } from 'src/app/shared-modules/material/material.module';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModulesModule } from '../shared-modules/shared-modules.module';
import { AgrappLogoComponent } from './agrapp-logo/agrapp-logo.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { AlliesComponent } from './allies/allies.component';
import { AnimateNumbersComponent } from './animate-numbers/animate-numbers.component';
import { ArrowsToScrollHorizontallyComponent } from './arrows-to-scroll-horizontally/arrows-to-scroll-horizontally.component';
import { BannerInvestComponent } from './banner-invest/banner-invest.component';
import { ButtonComponent } from './button/button.component';
import { CampaignAlertComponent } from './campaign-alert/campaign-alert.component';
import { CommunityMetricsComponent } from './community-metrics/community-metrics.component';
import { FocusImageComponent } from './focus-image/focus-image.component';
import { FooterParallaxComponent } from './footer-parallax/footer-parallax.component';
import { HowToVideoComponent } from './how-to-video/how-to-video.component';
import { IconComponent } from './icon/icon.component';
import { InteractiveTestimonyComponent } from './interactive-testimony/interactive-testimony.component';
import { MainHeroComponent } from './main-hero/main-hero.component';
import { MapInteractiveComponent } from './map-interactive/map-interactive.component';
import { PartnerProjectsComponent } from './partner-projects/partner-projects.component';
import { ProfileLeafComponent } from './profile-leaf/profile-leaf.component';
import { ProjectsComponent } from './projects/projects.component';
import { SectionComponent } from './section/section.component';
import { SimpleBannerComponent } from './simple-banner/simple-banner.component';
import { SimpleCallActionComponent } from './simple-call-action/simple-call-action.component';
import { SimpleHeroComponent } from './simple-hero/simple-hero.component';
import { SubsInitiativesComponent } from './subs-initiatives/subs-initiatives.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SupportComponent } from './support/support.component';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
import { MatIconModule } from '@angular/material/icon';

const COMPONENTS = [
  AlertDialogComponent,
  AlliesComponent,
  CommunityMetricsComponent,
  CampaignAlertComponent,
  FocusImageComponent,
  VideoDialogComponent,
  FooterParallaxComponent,
  MainHeroComponent,
  ProfileLeafComponent,
  SectionComponent,
  SimpleHeroComponent,
  SupportComponent,
  SubscriptionComponent,
  ProjectsComponent,
  SubsInitiativesComponent,
  HowToVideoComponent,
  InteractiveTestimonyComponent,
  SimpleCallActionComponent,
  SimpleBannerComponent,
  BannerInvestComponent,
  MapInteractiveComponent,
  PartnerProjectsComponent,
  AnimateNumbersComponent,
  AgrappLogoComponent,
  IconComponent,
  ButtonComponent,
  ArrowsToScrollHorizontallyComponent,
];

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModulesModule,
    MatCardModule,
    MatProgressBarModule,
    MatButtonModule,
    MaterialModule,
    SlickCarouselModule,
    NgxPageScrollModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    PipesModule,
    MatIconModule
  ],
  declarations: [...COMPONENTS],
  exports: [
    ...COMPONENTS,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  entryComponents: [VideoDialogComponent],
})
export class SharedComponentsModule {}
