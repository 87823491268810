import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsoleToggleService {

  constructor() {

  }

  disableConsoleInProduction(): void {
    if (environment.production) {
      console.warn('🚨 Console output is disabled on production!');
      console.log =  () => { };
      console.warn = () => { };
    }
  }
}
