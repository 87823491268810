import { Component, Input } from '@angular/core';
import { IconI } from '../../interfaces';
import { ICONS } from '../../constants';

@Component({
  selector: 'app-agrapp-logo',
  template: `
    <img
      [src]="AGRAPP_LOGO.src"
      [alt]="AGRAPP_LOGO.alt"
      [ngStyle]="{
        width: width,
        height: height,
        cursor: 'pointer'
      }"
    />
  `,
})
export class AgrappLogoComponent {
  @Input() width = '73px';
  @Input() height = '24px';

  public readonly AGRAPP_LOGO: IconI = ICONS.AGRAPP_FULL_LOGO;
}
